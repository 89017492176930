import React from "react";
import Classnames from "classnames";
import Logo from "../logo";
import UserMenu from "../user-menu";
import NavBarToggle from "./components/navbar-toggle";
import NavBarLink from "./components/navbar-link";
import ROUTES from "routes";
import DashboardIcon from "assets/icons/navigation/dashboard.svg";
import ActionItemsIcon from "assets/icons/navigation/action-items.svg";
import DocumentsIcon from "assets/icons/navigation/documents.svg";
import TeamIcon from "assets/icons/navigation/team.svg";
import CompanyIcon from "assets/icons/navigation/company.svg";
import DashboardIconActive from "assets/icons/navigation/dashboard-active.svg";
import ActionItemsIconActive from "assets/icons/navigation/action-items-active.svg";
import DocumentsIconActive from "assets/icons/navigation/documents-active.svg";
import TeamIconActive from "assets/icons/navigation/team-active.svg";
import CompanyIconActive from "assets/icons/navigation/company-active.svg";
import "./navbar.css";
import { IFlowFiData } from "types/flowfi";

interface INavBar {
  collapsed: boolean;
  data: IFlowFiData;
}

const NavBar = ({ collapsed, data }: INavBar): JSX.Element => {
  const navClasses = Classnames("app-navigation", {
    "app-navigation--collapsed": collapsed,
  });

  const toggleCollapse = () => {
    // navCollapsed(!navCollapsed());
  };

  return (
    <nav className={navClasses}>
      <Logo collapsed={collapsed} />
      <NavBarToggle onClick={toggleCollapse} collapsed={collapsed} />
      <ul className="navigation-items">
        <NavBarLink
          path={ROUTES.dashboard.path}
          label={ROUTES.dashboard.name}
          icon={DashboardIcon}
          activeIcon={DashboardIconActive}
          collapsed={collapsed}
        />
        <NavBarLink
          path={ROUTES.assets.path}
          label={ROUTES.assets.name}
          icon={DocumentsIcon}
          activeIcon={DocumentsIconActive}
          collapsed={collapsed}
        />
        <NavBarLink
          path={ROUTES.actionItems.path}
          label={ROUTES.actionItems.name}
          icon={ActionItemsIcon}
          activeIcon={ActionItemsIconActive}
          collapsed={collapsed}
          notificationCount={
            data.actionItems.filter((item) => item.state === "awaiting_reply")
              .length
          }
        />
        <NavBarLink
          path={ROUTES.team.path}
          label={ROUTES.team.name}
          icon={TeamIcon}
          activeIcon={TeamIconActive}
          collapsed={collapsed}
        />
        <NavBarLink
          path={`${ROUTES.settings.path}/companyprofile`}
          label={ROUTES.settings.name}
          icon={CompanyIcon}
          activeIcon={CompanyIconActive}
          collapsed={collapsed}
        />
      </ul>
      <UserMenu user={data.user} collapsed={collapsed} />
    </nav>
  );
};

export default NavBar;
