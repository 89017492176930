import React from "react";

import "./month-picker-dropdown.css";
import { RightArrow } from "assets/icons";
import { IBalanceMonthRange } from "components/month-picker";
import moment from "moment";

interface IMonthPickerDropdownProps {
  balanceMonthRange: IBalanceMonthRange;
  monthValue?: number;
  yearValue?: number;
  onChange: Function;
  forceCloseDropdown?: Function;
}

interface IMonthPickerDropdownState {
  year: number;
}

export class MonthPickerDropdown extends React.Component<IMonthPickerDropdownProps> {
  state: IMonthPickerDropdownState;

  constructor(props: IMonthPickerDropdownProps) {
    super(props);

    this.state = {
      year: this.props.yearValue
        ? this.props.yearValue
        : this.props.balanceMonthRange.endYear,
    };
  }

  pageYearLeft() {
    if (this.state.year > this.props.balanceMonthRange.startYear) {
      this.setState({ year: this.state.year - 1 });
    }
  }

  pageYearRight() {
    if (this.state.year < this.props.balanceMonthRange.endYear) {
      this.setState({ year: this.state.year + 1 });
    }
  }

  monthIsSelected(monthIdx: number) {
    return (
      monthIdx === this.props.monthValue &&
      this.state.year === this.props.yearValue
    );
  }

  monthIsDisabled(monthIdx: number) {
    return (
      (monthIdx < this.props.balanceMonthRange.startMonth &&
        this.state.year === this.props.balanceMonthRange.startYear) ||
      (monthIdx > this.props.balanceMonthRange.endMonth &&
        this.state.year === this.props.balanceMonthRange.endYear)
    );
  }

  monthSelectionHandler(monthKey: number, year: number) {
    this.props.onChange(monthKey, year);
    this.props.forceCloseDropdown && this.props.forceCloseDropdown();
  }
  render() {
    return (
      <div className="month-picker-dropdown">
        <div className="month-picker-dropdown__header">
          {this.state.year > this.props.balanceMonthRange.startYear && (
            <button
              className="month-picker-dropdown__page-button month-picker-dropdown__page-button-left"
              onClick={() => this.pageYearLeft()}
            >
              <img
                className="month-picker-dropdown__page-img month-picker-dropdown__page-img-left"
                src={RightArrow}
                alt="left arrow"
              />
            </button>
          )}
          <div className="month-picker-dropdown__year">{this.state.year}</div>
          {this.state.year < this.props.balanceMonthRange.endYear && (
            <button
              className="month-picker-dropdown__page-button month-picker-dropdown__page-button-right"
              onClick={() => this.pageYearRight()}
            >
              <img
                className="month-picker-dropdown__page-img"
                src={RightArrow}
                alt="right arrow"
              />
            </button>
          )}
        </div>
        <div className="month-picker-dropdown__months">
          {moment.months().map((month, idx) => (
            <button
              className={`month-picker-dropdown__month-button ${
                this.monthIsSelected(idx)
                  ? "month-picker-dropdown__month-button--selected"
                  : ""
              }`}
              key={month}
              onClick={() => this.monthSelectionHandler(idx, this.state.year)}
              disabled={
                (idx < this.props.balanceMonthRange.startMonth &&
                  this.state.year === this.props.balanceMonthRange.startYear) ||
                (idx > this.props.balanceMonthRange.endMonth &&
                  this.state.year === this.props.balanceMonthRange.endYear)
              }
            >
              {month}
            </button>
          ))}
        </div>
      </div>
    );
  }
}
