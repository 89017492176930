import { FlowToast } from "components/flow-toast";

async function requestPermission() {
  await window.Notification.requestPermission();
}

export default {
  onUpdate: (registration: ServiceWorkerRegistration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event: any) => {
        if (event?.target?.state === "activated") {
          // new update available
          FlowToast({
            message: "A new version is available.",
            update: true,
            duration: 604800,
            updateFunction: function () {
              window.location.reload();
            },
          });
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
  onSuccess: (registration: ServiceWorkerRegistration) => {
    console.info("service worker on success state");
    console.log(registration);
    requestPermission();
  },
};
