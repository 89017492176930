export default `
id
first_name
last_name
avatar
state
type
created
updated
`;
