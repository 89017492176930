import React from "react";
import { IUser } from "types/user";
import Classnames from "classnames";
import "./user-icon.css";

type UserIconSizes = "sm" | "md" | "lg" | "xl";

interface IUserIcon {
  size: UserIconSizes;
  user: IUser;
}

const getInitials = (user: IUser): string => {
  return `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`;
};

const UserIcon = ({ user, size }: IUserIcon): JSX.Element => {
  const userInitials = getInitials(user);

  const userClasses = Classnames("user-icon", {
    "user-icon--sm": size === "sm",
    "user-icon--md": size === "md",
    "user-icon--lg": size === "lg",
    "user-icon--xl": size === "xl",
  });

  return (
    <div className={userClasses}>
      {user?.avatar ? (
        <img
          src={user.avatar}
          alt={`${user.first_name} ${user.last_name}'s user avatar`}
        />
      ) : (
        <span>{userInitials}</span>
      )}
    </div>
  );
};

export default UserIcon;
