import React from "react";
import Classnames from "classnames";
import "./badge.css";

interface IBadge {
  count: number;
}

const Badge = ({ count }: IBadge): JSX.Element => {
  // this adds a little extra padding if the number doesn't fit the default size
  const badgeClasses = Classnames("badge", { "badge--extended": count > 9 });

  return <div className={badgeClasses}>{count}</div>;
};

export default Badge;
