import React from "react";
import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { IFlowSelectProps } from "./types";
import Classnames from "classnames";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  color: "white",
};

const GroupHeading = (props: any) => (
  <div style={groupStyles}>
    <components.GroupHeading {...props} />
  </div>
);

export const Select = (props: IFlowSelectProps) => {
  const { options, value, defaultValue, placeholder, transparent, disabled } =
    props;
  const selectedValue = options.find((option) => option.value === value);

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: `${
        transparent ? "var(--background-control)" : "var(--background-control)"
      }`,
      color: "#67E0D1",
      borderRadius: "10px",
      width: "100%",
      height: "35px",
      border: "1px solid var(--background-control)",
      boxShadow: "none",
      ":active": {
        ...styles[":active"],
        border: "none",
      },
      ":hover": {
        ...styles[":hover"],
        border: "1px solid #67E0D1",
      },
    }),
    option: (styles: { [x: string]: any }) => {
      return {
        ...styles,
        backgroundColor: "var(--background-control)",
        color: "white",
        display: "flex",
        alignItems: "center",
        fontSize: "1rem",
        ":hover": {
          ...styles[":hover"],
          backgroundColor: "#283845",
          color: "#67E0D1",
        },
      };
    },
    singleValue: (styles: { [x: string]: any }) => {
      return {
        ...styles,
        color: disabled ? "var(--font-main-gray)" : "white",
      };
    },
    input: (styles: { [x: string]: any }) => {
      return {
        ...styles,
        color: "white",
      };
    },
    menu: (styles: { [x: string]: any }) => {
      return {
        ...styles,
        backgroundColor: "#212535",
        borderRadius: "10px",
        overflow: "hidden",
      };
    },
    dropdownIndicator: (styles: { [x: string]: any }) => {
      return {
        ...styles,
        color: "#67E0D1",
        ":hover": {
          ...styles[":hover"],
          color: "#67E0D1",
        },
      };
    },
    group: (styles: { [x: string]: any }) => {
      return {
        ...styles,
      };
    },
    groupHeading: (styles: { [x: string]: any }) => {
      return {
        ...styles,
        color: "white",
        fontSize: "1rem",
      };
    },
    menuList: (base: any) => ({
      ...base,
      backgroundColor: "var(--background-control)",
      border: "none",
      padding: "0",
    }),
  };

  const classNames = Classnames({
    "opacity-50": disabled,
  });

  if (props.creatable) {
    return (
      <div className={classNames}>
        <CreatableSelect
          styles={colourStyles}
          options={options}
          placeholder={placeholder}
          isRtl={false}
          value={selectedValue}
          defaultValue={defaultValue}
          onChange={(e) => props.onChange(e)}
          components={{
            IndicatorSeparator: () => null,
            GroupHeading,
          }}
          isDisabled={props.disabled}
        />
      </div>
    );
  }

  return (
    <div className={classNames}>
      <ReactSelect
        styles={colourStyles}
        options={options}
        placeholder={placeholder}
        isRtl={false}
        value={selectedValue}
        defaultValue={defaultValue}
        onChange={(e) => props.onChange(e)}
        components={{
          IndicatorSeparator: () => null,
          GroupHeading,
        }}
        isDisabled={props.disabled}
      />
    </div>
  );
};
