import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import ROUTES from "routes";
import { Grid, Alert } from "../../assets/icons";
import { IFlowFiData } from "types/flowfi";
import SideNav from "components/side-nav";
import { ExportButton } from "components/export-button";
import { Icon } from "components/icon-with-badge";
import { ICardRefs } from "types/card";
import "./top-nav.css";
const WorkSpace = React.lazy(() => import("components/workspace"));

interface ITopNavProps {
  data: IFlowFiData;
  cardRefs: ICardRefs;
  selectCompany: (id: string) => void;
}

interface IRouteDefinition {
  name?: string;
  path: string;
}

const TopNav: React.FunctionComponent<ITopNavProps & RouteComponentProps> = (
  props: ITopNavProps & RouteComponentProps
) => {
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const { data, cardRefs, selectCompany } = props;
  const location = useLocation();

  // TODO: refine this to make it work for subroutes
  const currentRoute: any = ROUTES
    ? Object.values(ROUTES).filter(
        (route) => route.path === location.pathname
      )[0]
    : [];

  // Attaches an offClick handler if the flyout is open and removes it if closed.
  useEffect(() => {
    if (mobileDrawer) {
      window.addEventListener("click", handleOffClick);
    } else {
      window.removeEventListener("click", handleOffClick);
    }
  }, [mobileDrawer]);

  // Toggles visibility of the flyout.
  const toggleOpen = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setMobileDrawer(!mobileDrawer);
    },
    [mobileDrawer, setMobileDrawer]
  );

  // Closes the flyout.
  const handleOffClick = () => {
    setMobileDrawer(false);
  };

  return (
    <div className="flowfi-top-nav">
      <h2>{currentRoute ? currentRoute.name : ""}</h2>
      <div className="top-nav__buttons">
        {props.location.pathname === ROUTES.dashboard.path && (
          <ExportButton data={data} cardRefs={cardRefs} />
        )}
        <button
          className="flowfi-show-notifications"
          onClick={() => {}}
          disabled={true}
        >
          <Icon
            // badgeNumber={unreadNotifications.length}
            iconSrc={Alert}
            iconAlt={"Notifications"}
          />
        </button>

        <WorkSpace
          user={data?.user || {}}
          type={(data?.user.type as string) || ""}
          onWorkspaceClick={selectCompany}
          companies={data?.companies || []}
          members={data?.members || []}
        />
      </div>

      <button
        className="flowfi-mobile-menu-toggle appearance-none ml-8 lg:hidden"
        onClick={(e) => toggleOpen(e)}
      >
        <img src={Grid} style={{ transform: "rotate(90deg)" }} alt="Menu" />
      </button>
      <div className="block lg:hidden">
        <SideNav
          data={props.data}
          mobile={true}
          visible={mobileDrawer}
          onNavClick={() => setMobileDrawer(!mobileDrawer)}
        />
      </div>
    </div>
  );
};

export default withRouter((props: ITopNavProps & RouteComponentProps) => (
  <TopNav {...props} />
));
