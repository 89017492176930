import { ICompany } from "types/company";
import { ICompanyMember } from "types/company-member";
import { IConnection } from "types/connection";
import { FlowFiData } from "data/flowfi";
import { IEmail } from "types/email";
import { UserTypes } from "types/user-types";
import { getActiveCompanyId } from "utilities/routing";

import { get as getState, IStateResponse } from "resources/state";
import { get as getSession, ISessionData } from "system/session";
import { set as lsSet } from "system/ls";
import { set as ssSet } from "system/ss";
import UserCache from "caches/user";
import CompanyCache from "caches/company";
import { FlowToast } from "components/flow-toast";
import { decrypt } from "system/security";

const defaultCompany = {
  id: "",
  name: "",
  domain: "",
  avatar: "",
  state: "",
};

/**
 *
 * @param data
 * @param any
 */
export function membersToMapByUserId(data: ICompanyMember[] = []) {
  let obj: any = {};

  data.forEach((d) => {
    obj[d.user_id] = d;
  });

  return obj;
}

/**
 *
 * @param provider
 * @param company
 */
export function getCompanyConnectionByProvider(
  provider: string,
  connections: IConnection[]
) {
  return connections?.filter((c) => c?.provider === provider)[0];
}

/**
 *
 * @param name
 */
export function getCompanyInitials(name: string = "") {
  return (name || "")
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase())
    .join("");
}

/**
 *
 * @param email
 * @param companies
 */
export function getCompanyByEmail(
  email: string = "",
  companies: ICompany[],
  members: ICompanyMember[] = []
): ICompany {
  return companies.filter((c) => {
    return members?.filter((m) => m.email === email).length;
  })[0];
}

/**
 * Simply replaces spaces or underscores with dashes.
 * @param name
 */
export function formatCompanyDomainName(name: string) {
  let converted = (name || "").replace(/(\s|_)/g, "-");
  let stripped = converted.replace(
    /(\*|&|\\|\/|\(|\)|\^|%|\$|#|@|!|~|`|=|{|}|\[|\]|\||:|;|'|"|>|<|,|\?)/g,
    ""
  );

  return stripped.toLocaleLowerCase().slice(0, 31);
}

/**
 *
 * @param id
 * @param companies
 */
export function getCompanyIndexById(
  id: string = "",
  companies: ICompany[] = []
): number {
  let index = companies
    .map((c, idx) => ({ id: c.id, idx: idx }))
    .filter((c) => c.id === id)[0]?.idx;
  return index === undefined || null ? -1 : index;
}

/**
 *
 * @param members
 */
export function getCompanyFlowFiMembers(
  members: ICompanyMember[] = []
): ICompanyMember[] {
  return (
    members?.filter((member) => {
      const type = member.type;
      return type === "accountant" || type === "representative";
    }) ?? []
  );
}

/**
 *
 * @param members
 */
export function getCompanyLocalMembers(
  members: ICompanyMember[] = []
): ICompanyMember[] {
  return (
    members?.filter((member) => {
      const type = member.type;
      return type !== "accountant" && type !== "representative";
    }) ?? []
  );
}

/**
 *
 * @param id
 * @param company
 */
export function getMemberRoleByUserId(
  id: string = "",
  members: ICompanyMember[]
): string {
  let member = (members || []).filter((m) => m.user_id === id)[0];
  return (member && (member.role as string)) || "";
}

/**
 *
 * @param company
 */
export function getCompanyBankAccounts(connections: IConnection[]) {
  return connections
    ?.filter((conn) => conn.type === "bank")
    .map((conn) => conn.institution?.accounts ?? [])
    .reduce((acc, curr) => acc.concat(curr), []);
}

/**
 *
 * @param companies
 */
export function getActiveCompany(companies: ICompany[]): ICompany {
  const companyId = getActiveCompanyId();
  for (const company of companies) {
    if (company.id === companyId) return company;
  }
  return defaultCompany;
}

export function getActiveEmail(
  user_id: string,
  company_id: string,
  emails: IEmail[],
  members: ICompanyMember[]
): IEmail {
  return emails.filter((e) => {
    return members.filter(
      (m) =>
        m.company_id === company_id &&
        e.user_id === user_id &&
        m.email_id === e.id
    ).length;
  })[0];
}

export function isCompanySettingsViewer(type: string, role: string) {
  return (
    type === "developer" ||
    "admin" ||
    (type === "customer" && role === "member") ||
    "admin"
  );
}

export function isEditableViewer(type: string | UserTypes | undefined) {
  return type === "developer" || type === "admin" || type === "accountant";
}

export function companyIdIsValid(companies: ICompany[]) {
  const activeCompany = getActiveCompany(companies);
  return !!activeCompany.id;
}

export async function buildCompanyData(
  sessionToken: string | undefined = undefined
) {
  // avoid error message on app's initialization
  try {
    sessionToken ?? getSession();
  } catch {
    return;
  }

  try {
    // get user data from session
    const session =
      sessionToken === undefined
        ? getSession()
        : (decrypt(sessionToken) as ISessionData);
    let { user, companies } = session;

    // use data from existing cache, if possible, as it may have been modified since the session data was created
    const userCache = await UserCache.get();
    if (userCache.user?.id === user.id) {
      user = { ...userCache.user };
      if ((userCache.companies as any)[0].id) {
        companies = [...(userCache.companies || [])];
      }
    }

    // save user data that can be saved without company id (companies page needs the companies scope)
    await UserCache.set({ user, companies }, { clear: true });

    // get active company from the session company matching the company_id url search param
    const company = getActiveCompany(companies);
    if (!company.id) return;

    // set the company id to session storage so the companies page can preselect the last active company
    ssSet("lastActiveCompanyId", company.id);

    await CompanyCache.set(
      company.id,
      {
        assets: [...FlowFiData.assets],
        cards: [...FlowFiData.cards],
        connections: [...FlowFiData.connections],
        invitations: [...FlowFiData.invitations],
        members: [...FlowFiData.members],
        settings: { ...FlowFiData.settings },
        actionItems: { ...FlowFiData.actionItems },
        accounts: { ...FlowFiData.accounts },
        merchants: { ...FlowFiData.merchants },
      },
      { clear: true }
    );

    // request scopes
    const stateData = await getState(user.id, company.id);
    const {
      members,
      invitations,
      assets,
      connections,
      cards,
      emails,
      staff,
      actionItems,
      accounts,
      merchants,
    }: IStateResponse = {
      ...FlowFiData,
      ...stateData,
    };

    let { email } = userCache;
    if (!email?.id) {
      email = getActiveEmail(user.id, company.id, emails, members) || emails[0];

      if (!email?.id) {
        FlowToast({
          error: true,
          message:
            "We could not find a matching email for any of your companies. Please try logging in again",
        });
        return;
      }
    }

    lsSet("activeEmailId", email.id);

    // save user and company scopes to cache
    await UserCache.set({ emails, email, staff }, { clear: true });
    await CompanyCache.set(
      company.id,
      {
        members,
        invitations,
        assets,
        connections,
        cards,
        actionItems,
        merchants,
        accounts,
      },
      { clear: true }
    );
  } catch {
    FlowToast({
      error: true,
      message:
        "Something went wrong while fetching user data. Please try again.",
    });
  }
}
