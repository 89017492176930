import React from "react";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import Classnames from "classnames";
import "./side-nav.css";
import ROUTES from "routes";

import {
  Settings,
  SettingsActive,
  Dashboard,
  DashboardActive,
  Document,
  DocumentActive,
  Navigation,
  NavigationGreen,
  // Chat,
  // ChatActive,
  Team,
  TeamActive,
} from "assets/icons";
import { Icon } from "components/icon-with-badge";
import { IFlowFiData } from "types/flowfi";

interface ISideNavLinkProps {
  icons: {
    base: string;
    active: string;
  };
  name: string;
  to: string;
  // an optional param to use when determining if a link is "active." Defaults to parsing the URL instead.
  activeComparator?: string;
}

const SideNavLink = ({
  icons,
  name,
  to,
  location,
  activeComparator,
}: ISideNavLinkProps & RouteComponentProps) => {
  // to be considered active, a nav link's name must match the url location
  const comparator = activeComparator ?? name.toString().toLowerCase();
  const isActive = location.pathname.slice(1).includes(comparator);

  const activeRowClasses = Classnames(
    "flowfi-side-nav-name text-sm text-justify w-9/12",
    { "is-active": isActive }
  );
  const activeBorderClasses = Classnames("flowfi-side-nav-highlight", {
    "is-active": isActive,
  });

  return (
    <NavLink
      className="flowfi-side-nav-link"
      to={{
        pathname: to,
        search: location.search,
      }}
    >
      <div className="flex flex-row items-center">
        <div className={activeBorderClasses} />
        <div className="w-20 lg:w-2/12">
          <Icon iconSrc={isActive ? icons.active : icons.base} iconAlt={name} />
        </div>
        <div className={activeRowClasses}>{name}</div>
      </div>
    </NavLink>
  );
};

interface SideNavProps {
  data: IFlowFiData;
  mobile?: boolean;
  visible?: boolean;
  onNavClick?: Function;
}

class SideNav extends React.Component<RouteComponentProps & SideNavProps> {
  handleNavClick() {
    if (this.props.onNavClick) this.props.onNavClick();
    return;
  }

  render() {
    const props = this.props;
    const sideNavClasses = Classnames("menu", {
      "flowfi-mobile": props.mobile,
      "menu-visible": props.visible,
    });

    const actionItemsCount = props.data.actionItems
      ? props.data.actionItems?.filter(
          (item) => item.state === "awaiting_reply"
        ).length
      : 0;

    return (
      <nav className={sideNavClasses}>
        <ul className="menu-list">
          <li onClick={() => this.handleNavClick()}>
            <SideNavLink
              {...props}
              icons={{ base: Dashboard, active: DashboardActive }}
              name="Dashboard"
              to={ROUTES.dashboard.path}
            />
          </li>
          <li onClick={() => this.handleNavClick()}>
            <SideNavLink
              {...props}
              icons={{ base: Document, active: DocumentActive }}
              name="Documents"
              to={ROUTES.assets.path}
            />
          </li>
          <li onClick={() => this.handleNavClick()}>
            <SideNavLink
              {...props}
              icons={{ base: Navigation, active: NavigationGreen }}
              name={`Action Items (${actionItemsCount})`}
              to={ROUTES.actionItems.path}
              activeComparator="action-items"
            />
          </li>
          <li onClick={() => this.handleNavClick()}>
            <SideNavLink
              {...props}
              icons={{ base: Team, active: TeamActive }}
              name="Team"
              to={ROUTES.team.path}
            />
          </li>
          <li className="hidden lg:block" onClick={() => this.handleNavClick()}>
            <SideNavLink
              {...props}
              icons={{ base: Settings, active: SettingsActive }}
              name="Settings"
              to={`${ROUTES.settings.path}/userprofile`}
            />
          </li>
        </ul>
      </nav>
    );
  }
}

export default withRouter((props: RouteComponentProps & SideNavProps) => (
  <SideNav {...props} />
));
