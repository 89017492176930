export type TRouteDefinition = {
  path: string;
  subroutes?: {
    [key: string]: TRouteDefinition;
  };
};

export type IRouteDefinitions = typeof ROUTES;

const ROUTES = {
  root: {
    name: "Home",
    path: "/",
  },
  dashboard: {
    name: "Dashboard",
    path: "/dashboard",
  },
  registration: {
    name: "Registration",
    path: "/registration",
  },
  financials: {
    name: "Financials",
    path: "/financials",
  },
  search: {
    search: "Search",
    path: "/search/:search_query",
  },
  onboarding: {
    name: "Onboarding",
    path: "/onboarding",
    subroutes: {
      company: {
        path: "/onboarding",
      },
      accounts: {
        path: "/onboarding/accounts",
      },
      people: {
        path: "/onboarding/people",
      },
      team: {
        path: "/onboarding/team",
      },
      success: {
        path: "/onboarding/success",
      },
    },
  },
  onboarding2: {
    path: "/onboarding2",
  },
  login: {
    name: "Login",
    path: "/login/",
  },
  confirmation: {
    name: "Confirmation",
    path: "/confirmation",
  },
  settings: {
    name: "Company",
    path: "/settings",
    subroutes: {
      company: {
        path: "/settings/company",
      },
    },
  },
  invitation: {
    path: "/invitation/:token",
  },
  assets: {
    name: "Documents",
    path: "/documents",
    subroutes: {
      document: {
        path: "/documents/:document_id",
      },
    },
  },
  messages: {
    path: "/messages",
    subroutes: {
      conversation: {
        path: "/messages/:conversation_id/:message_id",
        subroutes: {
          message: {
            path: "/message/:message_id",
          },
        },
      },
    },
  },
  team: {
    name: "Team",
    path: "/team",
  },
  insights: {
    path: "/insights",
  },
  transactions: {
    path: "/transactions",
    subroutes: {
      transaction: {
        path: "/transactions/:id",
        subroutes: {
          vendor: {
            path: "/transactions/:tid/vendors/:vid",
          },
        },
      },
    },
  },
  recurring: {
    path: "/recurring",
  },
  error: {
    path: "/pageNotFound",
  },
  companies: {
    path: "/companies",
  },
  actionItems: {
    name: "Action Items",
    path: "/action-items",
    subroutes: {
      toDo: {
        name: "To Do",
        path: "/action-items/to-do",
      },
      pendingUpdates: {
        name: "Pending Updates",
        path: "/action-items/pending",
      },
    },
  },
};

export default ROUTES;
