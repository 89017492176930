import { ICompany } from "types/company";
import { Response } from "system/resource";
import Company from "resources/models/company";
import axios from "axios";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

export interface ICompaniesStateResponse {
  companies: ICompany[];
}

/**
 * Merge Interfaces
 */
export interface CompaniesStateResponse extends ICompaniesStateResponse {}

/**
 * Merge Classes
 */
export class CompaniesStateResponse
  extends Response
  implements CompaniesStateResponse {}

/**
 *
 * @param user_id
 */
export default async function companies(user_id: string) {
  const body = JSON.stringify({
    query: `query getStateCompanies($user_id: ID!) {
      getStateCompanies(user_id: $user_id) {
        companies {
          ${Company}
        }
      }
    }`,
    variables: {
      user_id,
    },
  });

  const request = (await api.post("", body)).data?.data?.getStateCompanies;
  return new CompaniesStateResponse(request?.__typename, request);
}
