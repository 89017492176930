export default `
id
role
state
company_id
user_id
first_name
last_name
email
email_id
avatar
created
updated
type
status
`;
