import React from "react";
const Invite = React.lazy(() => import("./Invite"));
const Registration = React.lazy(() => import("./Registration"));
const Settings = React.lazy(() => import("./settings"));
const Dashboard = React.lazy(() => import("./Dashboard"));
const Login = React.lazy(() => import("./login"));
const Documents = React.lazy(() => import("./Documents"));
const Team = React.lazy(() => import("./team"));
const Document = React.lazy(
  () => import("./Documents/components/document-full")
);
const Companies = React.lazy(() => import("./companies"));
const ActionItems = React.lazy(() => import("./action-items"));

export {
  Documents,
  Document,
  Invite,
  Registration,
  Settings,
  Dashboard,
  Login,
  Team,
  Companies,
  ActionItems,
};
