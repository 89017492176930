import React from "react";

import "./confirm-buttons.css";

export interface IConfirmButtonsProps {
  leftButtonLabel: string;
  rightButtonLabel: string;
  leftButtonValidators: boolean[];
  rightButtonValidators: boolean[];
  leftButtonOnClick: () => void;
  rightButtonOnClick: () => void;
  leftButtonInFlight?: boolean;
  rightButtonInFlight?: boolean;
  className?: string;
}

export class ConfirmButtons extends React.Component<IConfirmButtonsProps> {
  render() {
    return (
      <div className="confirm-buttons__relative-wrapper">
        <div className={`confirm-buttons ${this.props.className}`}>
          <button
            className="confirm-buttons__button confirm-buttons__button-left"
            disabled={
              this.props.leftButtonValidators.some(
                (validator) => validator === false
              ) || !!this.props.leftButtonInFlight
            }
            onClick={this.props.leftButtonOnClick}
          >
            {this.props.leftButtonLabel}
          </button>
          <button
            className="confirm-buttons__button confirm-buttons__button-right"
            disabled={
              this.props.rightButtonValidators.some(
                (validator) => validator === false
              ) || !!this.props.rightButtonInFlight
            }
            onClick={this.props.rightButtonOnClick}
          >
            {this.props.rightButtonLabel}
          </button>
        </div>
      </div>
    );
  }
}
