import moment, { Moment, MomentInput } from "moment";

const normalizedDate = (date: MomentInput = undefined) => {
  const inputDate = moment(date);
  const year = inputDate.year();
  const month = inputDate.month();
  const day = inputDate.date();
  return moment.utc({ year, month, day });
};

const normalizedMonth = (date: MomentInput = undefined) => {
  return normalizedDate(date).startOf("month");
};

interface IFlowFiDateInput {
  date?: MomentInput;
  detail?: "time" | "day" | "month";
}
export const flowFiDate = (dateInput: IFlowFiDateInput = {}): Moment => {
  const { date, detail } = dateInput;
  switch (detail) {
    case "time":
      return moment.utc(date);
    case "day":
      return normalizedDate(date);
    case "month":
      return normalizedMonth(date);
    default:
      return normalizedDate(date);
  }
};
