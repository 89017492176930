const ActionItem = `
  id
  company_id
  bookkeeper_id
  due_date
  question
  category
  state
  amortization_responses {
    id
    action_item_id
    is_amortization
    start_date
    service_period_months
    additional_notes
    author_id
    response_time
  }
  categorization_responses {
    id
    action_item_id
    name
    account
    additional_notes
    author_id
    response_time
  }
  miscellaneous_responses {
    id
    action_item_id
    long_answer
    author_id
    response_time
  }
`;

export default ActionItem;
