import React, { useState, useEffect, useRef } from "react";
import "./checkbox.css";
import Classnames from "classnames";

export type CheckboxStates = 1 | 0 | -1;

interface ICheckboxProps {
  name: string;
  id: string;
  label: string;
  value: CheckboxStates;
  disabled?: boolean;
  onChange: Function;
  darkMode?: boolean;
}

export const FlowFiCheckbox = ({
  name,
  id,
  label,
  value,
  disabled,
  onChange,
  darkMode,
}: ICheckboxProps) => {
  const elRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (elRef.current) {
      elRef.current.checked = value === 1;
      elRef.current.indeterminate = value === -1;
    }
    setChecked(value === 1);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!(onChange instanceof Function)) return;

    onChange(e);
  };

  const inputClasses = Classnames("flowfi-checkbox", {
    "theme-inverted": darkMode,
    indeterminate: value === -1,
  });

  return (
    <div className="flowfi-checkbox__wrapper">
      <input
        ref={elRef}
        className={inputClasses}
        type="checkbox"
        name={name}
        id={id}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
        aria-labelledby={`label_${id}`}
        aria-checked={checked}
      />
      <label className="flowfi-checkbox__label" id={`label_${id}`} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
