import { IConnection } from "types/connection";
import { Response } from "system/resource";
import Connection from "resources/models/connection";
import axios from "axios";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 *
 */
export interface IConnectionsStateResponse {
  connections: IConnection[];
}

/**
 * Merge Interfaces
 */
export interface ConnectionsStateResponse extends IConnectionsStateResponse {}

/**
 * Merge Classes
 */
export class ConnectionsStateResponse
  extends Response
  implements ConnectionsStateResponse {}

/**
 *
 * @param user_id
 * @param company_id
 */
export default async function connections(user_id: string, company_id: string) {
  const body = JSON.stringify({
    query: `query getStateConnections($user_id: ID!, $company_id: ID!) {
      getStateConnections(user_id: $user_id, company_id: $company_id) {
        connections {
          ${Connection}
        }
      }
    }`,
    variables: {
      user_id,
      company_id,
    },
  });

  const request = (await api.post("", body)).data?.data?.getStateConnections;
  return new ConnectionsStateResponse(request?.__typename, request);
}
