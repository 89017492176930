import ErrorBoundary from "components/error-boundary";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBarProgress from "react-topbar-progress-indicator";
import { AppContainer } from "./app-container";
import Config from "./swConfig";
import * as ServiceWorker from "./swRegistrar";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

TopBarProgress.config({
  barColors: {
    "0": "#00E4D1",
    "0.5": "#02A5A0",
    "1.0": "#070044",
  },
  barThickness: 3,
  shadowBlur: 5,
});

class FlowFi extends React.Component {
  render() {
    return (
      <>
        <ErrorBoundary>
          <AppContainer />
          <ToastContainer />
        </ErrorBoundary>
      </>
    );
  }
}

/**
 * Render Application.
 */
ReactDOM.render(<FlowFi />, document.getElementById("root"));

ServiceWorker.register(Config);
