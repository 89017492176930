import { ICompanyMember } from "types/company-member";
import { Response } from "system/resource";
import CompanyMember from "resources/models/company-member";
import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

export interface IMembersStateResponse {
  members: ICompanyMember[];
}

/**
 * Merge Interfaces
 */
export interface MembersStateResponse extends IMembersStateResponse {}

/**
 * Merge Classes
 */
export class MembersStateResponse
  extends Response
  implements MembersStateResponse {}

/**
 *
 * @param user_id
 * @param company_id
 */
export default async function members(user_id: string, company_id: string) {
  const body = JSON.stringify({
    query: `query getStateMembers($user_id: ID!, $company_id: ID!) {
      getStateMembers(user_id: $user_id, company_id: $company_id) {
        members {
          ${CompanyMember}
        }
      }
    }`,
    variables: {
      user_id,
      company_id,
    },
  });

  const request = (await api.post("", body)).data?.data?.getStateMembers;
  return new MembersStateResponse(request?.__typename, request);
}
