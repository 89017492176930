import axios from "axios";
import { ICardImage } from "types/card";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_EXPORTS_API}/exports`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default { images, reports, subscriptions };

async function images(
  userId: string,
  companyId: string,
  companyName: string,
  dateStamp: string,
  images: ICardImage[]
): Promise<string> {
  const payload = JSON.stringify({
    userId,
    companyId,
    companyName,
    dateStamp,
    images,
  });

  return (await api.post("/images", payload)).data;
}

async function reports(
  userId: string,
  companyId: string,
  companyName: string,
  connectionId: string,
  start: string,
  stop: string,
  reports: string[]
): Promise<string> {
  const payload = JSON.stringify({
    userId,
    companyId,
    companyName,
    connectionId,
    start,
    stop,
    reports,
  });

  return (await api.post("/reports", payload)).data;
}

async function subscriptions(
  companyId: string,
  companyName: string
): Promise<string> {
  const payload = JSON.stringify({
    companyId,
    companyName,
  });

  return (await api.post("/subscriptions", payload)).data;
}
