import React from "react";

import { SelectCustom } from "components/select";
import { MonthPickerDropdown } from "./month-picker-dropdown";
import moment from "moment";

export interface IBalanceMonthRange {
  startYear: number;
  endYear: number;
  startMonth: number;
  endMonth: number;
}

interface IMonthPickerProps {
  balanceMonthRange: IBalanceMonthRange;
  startMonthValue?: number;
  endMonthValue?: number;
  startYearValue?: number;
  endYearValue?: number;
  onStartChange: Function;
  onEndChange: Function;
}

export class MonthPicker extends React.Component<IMonthPickerProps> {
  render() {
    return (
      <div className="columns">
        <div className="column is-half">
          <label>From</label>
          <SelectCustom
            value={
              this.props.startMonthValue !== undefined
                ? `${moment.months()[this.props.startMonthValue]} ${
                    this.props.startYearValue
                  }`
                : undefined
            }
            placeholder="Select Start Month"
            component={
              <MonthPickerDropdown
                balanceMonthRange={this.props.balanceMonthRange}
                monthValue={this.props.startMonthValue}
                yearValue={this.props.startYearValue}
                onChange={this.props.onStartChange}
              />
            }
          />
        </div>
        <div className="column is-half">
          <label>To</label>
          <SelectCustom
            value={
              this.props.endMonthValue !== undefined
                ? `${moment.months()[this.props.endMonthValue]} ${
                    this.props.endYearValue
                  }`
                : undefined
            }
            placeholder="Select End Month"
            component={
              <MonthPickerDropdown
                balanceMonthRange={this.props.balanceMonthRange}
                monthValue={this.props.endMonthValue}
                yearValue={this.props.endYearValue}
                onChange={this.props.onEndChange}
              />
            }
          />
        </div>
      </div>
    );
  }
}
