import React from "react";
import ROUTES from "routes";
import { getActiveCompanyId, getPathWithCompanyId } from "utilities/routing";
interface IErrorProps {
  children?: any;
  errorPage?: Boolean;
}
interface IErrorState {
  hasError: boolean;
}
export default class ErrorBoundary extends React.Component<IErrorProps> {
  state: IErrorState;
  constructor(props: IErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError || this.props.errorPage) {
      return (
        <div className="flowfi flowfi-page is-flex is-justify-content-center is-align-items-center">
          <div className="columns">
            <div className="column is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
              <h1>Something went wrong.</h1>
              <h3>Our team will look into the problem.</h3>
              <div className="is-4 mt-6">
                <button
                  className="button is-primary is-medium"
                  onClick={() => {
                    window.location.hash = `${getPathWithCompanyId(
                      ROUTES.dashboard.path,
                      getActiveCompanyId()
                    )}`;
                    window.location.reload();
                  }}
                >
                  Return to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
