import { IFlowFiData } from "types/flowfi";

/**
 * This is a blank object that contains the data structure for the app. Used to initialize the store.
 */
export const FlowFiData: IFlowFiData = {
  application: {
    progress: [],
    version: 0,
    maintenance: { active: false, message: "", duration: "", start: "" },
  },
  user: {
    id: "",
    first_name: "",
    last_name: "",
    avatar: "",
    state: "active",
    created: "",
    updated: "",
    type: "",
  },
  email: {
    id: "",
    address: "",
    user_id: "",
    state: "",
  },
  billing: {
    company_id: "",
    subscription: {
      id: "",
      stripe_card_id: "",
      name: "",
      next: "",
      status: "",
      stripe_customer_id: "",
      stripe_subscription_id: "",
    },
    methods: [],
    payments: [],
  },
  connection: {},
  emails: [],
  companies: [],
  connections: [],
  assets: [],
  members: [],
  invitations: [],
  cards: [],
  staff: [],
  settings: [],
  actionItems: [],
  accounts: [],
  merchants: [],
};
