import React from "react";
import { FlowFiCheckbox, CheckboxStates } from "components/checkbox";

import "./checklist.css";

export interface IChecklistCheck {
  name: string;
  label: string;
  value: CheckboxStates;
}

export type CheckValues = { [key: string]: CheckboxStates };
interface IChecklistProps {
  checks: IChecklistCheck[];
  handleCheck: (checkValues: CheckValues) => void;
  title?: string;
}

export class Checklist extends React.Component<IChecklistProps> {
  determineSelectAllState(checks: IChecklistCheck[]) {
    if (checks.every((check) => check.value === 1)) return 1;
    else if (checks.every((check) => check.value === 0)) return 0;
    else return -1;
  }

  /**
   * Creates a new complete CheckValues object, replacing
   * only the value of the box checked with the new value.
   * Passes new CheckValues object to the handleCheck callback.
   */
  handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkValues: CheckValues = {};
    this.props.checks.forEach((check) => {
      if (event.target.name === check.name) {
        Object.assign(checkValues, {
          [check.name]: event.target.checked ? 1 : 0,
        });
      } else {
        Object.assign(checkValues, { [check.name]: check.value });
      }
    });
    this.props.handleCheck(checkValues);
  };

  /**
   * Creates a new complete CheckValues object, replacing
   * all values with the new value of the 'Select All' checkbox.
   * Passes new CheckValues object to the handleCheck callback.
   */
  handleSelectAllCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked ? 1 : 0;
    this.setState({ selectAllValue: value });

    const checkValues: CheckValues = {};
    this.props.checks.forEach((check) => {
      Object.assign(checkValues, { [check.name]: value });
    });
    this.props.handleCheck(checkValues);
  };

  render() {
    const selectAllValue = this.determineSelectAllState(this.props.checks);

    return (
      <div className="checklist">
        <header className="checklist__header">
          <h3>{this.props.title}</h3>
          <FlowFiCheckbox
            id={"select-all"}
            name={"select-all"}
            label={`Select All`}
            value={selectAllValue}
            onChange={this.handleSelectAllCheck}
          />
        </header>
        <div className="checklist__checks">
          {this.props.checks.map((check) => (
            <FlowFiCheckbox
              key={check.name}
              id={check.name}
              name={check.name}
              label={check.label}
              value={check.value}
              onChange={this.handleCheck}
            />
          ))}
        </div>
      </div>
    );
  }
}
