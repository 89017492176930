import { IInvite } from "types/invite";
import { Response } from "system/resource";
import Invite from "resources/models/invite";
import axios from "axios";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 *
 */
export interface IInvitationsStateResponse {
  invitations: IInvite[];
}

/**
 * Merge Interfaces
 */
export interface InvitationsStateResponse extends IInvitationsStateResponse {}

/**
 * Merge CLasses
 */
export class InvitationsStateResponse
  extends Response
  implements IInvitationsStateResponse {}

/**
 *
 * @param user_id
 * @param company_id
 */
export default async function invitations(user_id: string, company_id: string) {
  const body = JSON.stringify({
    query: `query getStateInvitations($user_id: ID!, $company_id: ID!, $config: QueryConfig) {
      getStateInvitations(user_id: $user_id, company_id: $company_id, config: $config) {
        invitations {
          ${Invite}
        }
      }
    }`,
    variables: {
      user_id,
      company_id,
      config: { limit: 200 },
    },
  });

  const request = (await api.post("", body)).data?.data?.getStateInvitations;
  return new InvitationsStateResponse(request?.__typename, request);
}
