import React from "react";
import { toast } from "react-toastify";

interface IFlowToastProps {
  message?: string;
  error?: boolean;
  success?: boolean;
  update?: boolean;
  duration?: number;
  updateFunction?: () => void;
}

export const FlowToast = (props: IFlowToastProps) => {
  const {
    error,
    success,
    message = "",
    update,
    duration,
    updateFunction = () => {},
  } = props;
  if (error && message) {
    return toast.error(message, {
      position: "bottom-center",
      autoClose: duration || 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      bodyClassName: "toast--body",
      className: "toast--error",
      closeButton: false,
    });
  } else if (error && !message) {
    return toast.error("Something went wrong. Please try again.", {
      position: "bottom-center",
      autoClose: duration || 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      bodyClassName: "toast--body",
      className: "toast--error",
      closeButton: false,
    });
  } else if (success) {
    return toast.success(message || "Your action was successful!", {
      position: "bottom-center",
      autoClose: duration || 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      bodyClassName: "toast--body",
      className: "toast--success",
      closeButton: false,
    });
  } else if (update && message) {
    return toast.info(
      <div className="columns is-vcentered is-mobile">
        <div className="column is-8-desktop is-6-touch">
          <h4>{message}</h4>
        </div>
        <div className="column is-4-desktop is-6-touch">
          <button
            className="button is-default is-full-width"
            onClick={updateFunction}
          >
            Update
          </button>
        </div>
      </div>,
      {
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        bodyClassName: "toast--body",
        className: "toast--info",
        closeButton: false,
      }
    );
  } else {
    return toast.info(message, {
      position: "bottom-center",
      autoClose: duration || 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      bodyClassName: "toast--body",
      className: "toast--info",
      closeButton: false,
    });
  }
};
