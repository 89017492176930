import axios from "axios";
import { Response } from "system/resource";
import { IAsset } from "types/asset";
import { ICompany } from "types/company";
import { ICompanyMember } from "types/company-member";
import { IConnection } from "types/connection";
import { IEmail } from "types/email";
import { IUser } from "types/user";
import { IInvite } from "types/invite";
import { ICompanyUserSettings } from "types/settings";
import { ICard } from "types/card";
import { IStaff } from "types/staff";
import User from "resources/models/user";
import Company from "resources/models/company";
import Connection from "resources/models/connection";
import Asset from "resources/models/asset";
import CompanyMember from "resources/models/company-member";
import Email from "resources/models/email";
import Invite from "resources/models/invite";
import Staff from "resources/models/staff";
import Card from "resources/models/card";
import ActionItem from "resources/models/action-item";

/**
 *
 */
import connections, {
  ConnectionsStateResponse,
} from "resources/state/connections";
import members, { MembersStateResponse } from "resources/state/members";
import companies, { CompaniesStateResponse } from "resources/state/companies";
import assets, { AssetsStateResponse } from "resources/state/assets";
import emails, { EmailStateResponse } from "resources/state/emails";
import invitations, {
  InvitationsStateResponse,
} from "resources/state/invitations";
import cards, { ICardStateResponse } from "./cards";
import staff, { StaffStateResponse } from "./staff";
import { IActionItem } from "types/action-items";
import { IAccount } from "types/account";
import { IMerchant } from "types/merchant";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

const StateHandlers: IStateHandlers = {
  get,
  assets,
  emails,
  companies,
  connections,
  members,
  invitations,
  cards,
  staff,
};

export default StateHandlers;

export interface IStateHandlers {
  get: Function;
  companies: (id: string) => Promise<CompaniesStateResponse>;
  members: (id: string, company_id: string) => Promise<MembersStateResponse>;
  emails: (id: string) => Promise<EmailStateResponse>;
  connections: (
    id: string,
    company_id: string
  ) => Promise<ConnectionsStateResponse>;
  assets: (id: string, company_id: string) => Promise<AssetsStateResponse>;
  invitations: (
    id: string,
    company_id: string
  ) => Promise<InvitationsStateResponse>;
  cards: (id: string, company_id: string) => Promise<ICardStateResponse>;
  staff: (id: string) => Promise<StaffStateResponse>;
}

export interface IStateResponse {
  user: IUser;
  email: IEmail;
  emails: IEmail[];
  companies: ICompany[];
  members: ICompanyMember[];
  cards: ICard[];
  connections: IConnection[];
  assets: IAsset[];
  invitations: IInvite[];
  settings: ICompanyUserSettings[];
  staff: IStaff[];
  actionItems: IActionItem[];
  accounts: IAccount[];
  merchants: IMerchant[];
}

/**
 * Merge Interfaces
 */
interface StateResponse extends IStateResponse {}

/**
 * Merge Classes
 */
class StateResponse extends Response implements IStateResponse {}

/**
 * @TODO add back cards scope to get() function
 */
// cards {
//   ${CardQuery}
// }

/**
 *
 * @param user_id
 * @param company_id
 */
export async function get(user_id: string, company_id: string) {
  const body = JSON.stringify({
    query: `query getState($user_id: ID!, $company_id: ID!, $config: QueryConfig) {
      getState(user_id: $user_id, company_id: $company_id, config: $config) {
        user {
          ${User}
        }

        emails {
          ${Email}
        }

        companies {
          ${Company}
        }

        staff {
          ${Staff}
        }

        invitations {
          ${Invite}
        }

        connections {
          ${Connection}
        }

        members {
          ${CompanyMember}
        }

        assets {
          ${Asset}
        }

        cards {
          ${Card}
        }

        action_items {
          ${ActionItem}
        }

        merchants {
          id
          companyId
          name
          type
        }

        accounts {
          id
          name
          number
          type
        }
      }
    }`,
    variables: {
      user_id,
      company_id,
      config: { limit: 200 },
    },
  });
  const response = (await api.post("", body)).data?.data?.getState;

  // translate action_items to actionItems to be more javascripty
  return new StateResponse(response?.__typename, {
    ...response,
    actionItems: response.action_items,
  });
}
