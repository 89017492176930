import React from "react";
import { v4 } from "uuid";

import "./select-custom.css";
import { DownArrow } from "assets/icons";

interface ISelectCustomProps {
  value: any;
  placeholder: string;
  component: JSX.Element;
}

interface ISelectCustomState {
  dropdownVisible: boolean;
  keepDropdownOpenClass: string;
}

export class SelectCustom extends React.Component<ISelectCustomProps> {
  state: ISelectCustomState;
  body: Element | null;

  constructor(props: ISelectCustomProps) {
    super(props);

    this.state = {
      dropdownVisible: false,
      keepDropdownOpenClass: `select-custom__keep-open-${v4()}`,
    };

    this.body = document.querySelector("body");
  }

  toggleDropdown = () => {
    this.setState({ dropdownVisible: !this.state.dropdownVisible });
    this.body?.addEventListener("click", this.closeDropdown);
  };

  closeDropdown = (e: any) => {
    const clickIsOutside: boolean = !e.target.closest(
      `.${this.state.keepDropdownOpenClass}`
    );
    if (clickIsOutside) this.setState({ dropdownVisible: false });
  };

  forceCloseDropdown = () => this.setState({ dropdownVisible: false });

  render() {
    return (
      <>
        <button
          className={`select-custom__button ${this.state.keepDropdownOpenClass}`}
          onClick={() => this.toggleDropdown()}
          style={{
            border: `1px solid ${
              this.state.dropdownVisible ? "#67E0D1" : "#27293E"
            }`,
          }}
        >
          {this.props.value ? this.props.value : this.props.placeholder}
          <img src={DownArrow} alt="down arrow" style={{ height: "9px" }} />
        </button>
        {this.state.dropdownVisible && (
          <div
            className={`select-custom__dropdown ${this.state.keepDropdownOpenClass}`}
          >
            {React.cloneElement(this.props.component, {
              forceCloseDropdown: this.forceCloseDropdown,
            })}
          </div>
        )}
      </>
    );
  }
}
