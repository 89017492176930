import { v4 } from "uuid";
import { IFlowFiData } from "types/flowfi";
import { clone } from "ramda";
import { FlowFiData } from "data/flowfi";

export interface ICacheSubscription {
  [key: string]: Function;
}

export type TCompanyDataScopes =
  | "assets"
  | "cards"
  | "connections"
  | "invitations"
  | "members"
  | "actionItems"
  | "accounts"
  | "merchants"
  | "settings";

type TCompanyStore = Partial<Pick<IFlowFiData, TCompanyDataScopes>>;

const { assets, cards, connections, invitations, members, settings } =
  FlowFiData;

export const initialCompanyStore: TCompanyStore = {
  assets,
  cards,
  connections,
  invitations,
  members,
  settings,
};

const companyStore = clone(initialCompanyStore);

/**
 *
 */
class CompanyCache {
  private subscriptions: ICacheSubscription = {};

  /**
   *
   * @param callback
   */
  subscribe(callback: Function): ICacheSubscription {
    let id = v4();

    this.subscriptions[id] = callback;

    return {
      unsubscribe: () => {
        delete this.subscriptions[id];
      },
    };
  }

  /**
   *
   * @param data
   */
  dispatch(data: Partial<IFlowFiData>) {
    Object.keys(this.subscriptions).forEach((sub) =>
      this.subscriptions[sub](data)
    );
  }

  /**
   *
   * @param companyId
   * @param data
   * @param config
   */
  async set(
    companyId: string = "",
    data: TCompanyStore,
    config: { clear: boolean } = { clear: false }
  ): Promise<TCompanyStore> {
    if (config.clear) {
      this.clear(Object.keys(data) as TCompanyDataScopes[]);
    }
    // deep copy each slice of state passed in and save it to the store
    Object.entries(data).forEach(([key, value]) => {
      Object.assign(companyStore, { [key]: clone(value) });
    });

    this.dispatch(companyStore);
    return companyStore;
  }

  /**
   *
   * @param scopes
   * @param company_id
   */
  async get(
    scopes: TCompanyDataScopes[] = [],
    company_id: string = ""
  ): Promise<TCompanyStore> {
    const result: TCompanyStore = {};

    // get each scope from the store and return them in a new object
    scopes.forEach((scope) => {
      Object.assign(result, { [scope]: clone(companyStore[scope]) });
    });
    return result;
  }

  async clear(
    scopes: TCompanyDataScopes[] = Object.keys(
      initialCompanyStore
    ) as TCompanyDataScopes[],
    company_id: string = ""
  ): Promise<TCompanyStore> {
    const defaultCompanyData = clone(initialCompanyStore);

    // set each scope in the store to its default value
    scopes.forEach((scope) => {
      Object.assign(companyStore, { [scope]: defaultCompanyData[scope] });
    });

    this.dispatch(companyStore);
    return companyStore;
  }
}

export default new CompanyCache();
