import { Response } from "system/resource";
import axios from "axios";
import { ICard } from "types/card";
import CardQuery from "resources/models/card";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 *
 */
export interface ICardStateResponse {
  cards: ICard[];
}

/**
 * Merge Interfaces
 */
export interface CompanyCardsStateResponse extends ICardStateResponse {}

/**
 * Merge Classes
 */
export class CompanyCardsStateResponse
  extends Response
  implements CompanyCardsStateResponse {}

/**
 *
 * @param user_id
 * @param company_id
 */
export default async function cards(user_id: string, company_id: string) {
  const body = JSON.stringify({
    query: `query getStateCards($user_id: ID!, $company_id: ID!) {
      getStateCards(user_id: $user_id, company_id: $company_id) {
        cards {
          ${CardQuery}
        }
      }
    }`,
    variables: {
      user_id,
      company_id,
    },
  });

  const request = (await api.post("", body)).data?.data?.getStateCards;
  return new CompanyCardsStateResponse(request?.__typename, request);
}
