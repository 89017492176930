import React, { useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import UserIcon from "components/user-icon";
import { NavLink } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { IUser } from "types/user";
import ChevronDown from "assets/icons/chevron-down.svg";
import ChevronActive from "assets/icons/chevron-right-blue.svg";
import { logout } from "utilities/general";
import "./user-menu.css";

interface IUserMenu {
  user: IUser;
  collapsed: boolean;
}

const UserMenu = ({ user, collapsed }: IUserMenu): JSX.Element => {
  const [hovering, setHovering] = useState<boolean>(false);
  const location = useLocation();

  // Since the popover button itself isn't a navigation item, we use this to set
  // an 'active' class on it if the user is on a profile route/subroute
  const linkActive = (): boolean => {
    return !!matchPath(`/settings/userprofile`, location.pathname);
  };

  return (
    <div className="user-menu">
      <Popover>
        {({ open }) => (
          <>
            {/* We don't use Classnames on this component since the HeadlessUI docs prefer this method */}
            <Popover.Button
              className={`user-menu__button ${
                open ? "user-menu__button--open" : ""
              } ${linkActive() ? "user-menu__button--route-active" : ""}`}
              onMouseOver={() => setHovering(true)}
              onMouseOut={() => setHovering(false)}
            >
              <UserIcon size="sm" user={user} />
              {!collapsed && (
                <>
                  <span>
                    {user.first_name} {user.last_name}
                  </span>
                  <img
                    src={open || hovering ? ChevronActive : ChevronDown}
                    alt=""
                  />
                </>
              )}
            </Popover.Button>

            <Popover.Panel className="user-menu__popover">
              {/* "as" prop will swap in a named component and treat it like a button */}
              <Popover.Button
                className="user-menu__link"
                as={NavLink}
                to={{
                  pathname: "/settings/userprofile",
                  search: location.search,
                }}
              >
                My Profile
              </Popover.Button>
              <Popover.Button
                className="user-menu__logout"
                onClick={async () => await logout()}
              >
                Sign Out
              </Popover.Button>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default UserMenu;
