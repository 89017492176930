export default `
id
name
description
created
updated
url
user_id
company_id
thumb_url
preview_url
tags
first_name
last_name
user_role
user_type
`;
