import { FlowToast } from "components/flow-toast";
import { IEmail } from "types/email";
import { FlowFiData } from "data/flowfi";
import { remove as delSession } from "system/session";
import UserCache from "caches/user";
import CompanyCache from "caches/company";
import { TUserDataScopes, initialUserStore } from "caches/user";
import { TCompanyDataScopes, initialCompanyStore } from "caches/company";
import { getActiveCompanyId, getPathWithCompanyId } from "utilities/routing";
import ROUTES from "routes";

interface IArrayToMap {
  [key: string]: any;
}

/**
 *
 */
export async function logout() {
  const companyId = getActiveCompanyId();
  await UserCache.clear(Object.keys(initialUserStore) as TUserDataScopes[]);
  await CompanyCache.clear(
    Object.keys(initialCompanyStore) as TCompanyDataScopes[],
    companyId
  );
  window.localStorage.removeItem("FLOWFI.billDotComMfa");
  const hash = getPathWithCompanyId(ROUTES.login.path, companyId);
  window.location.hash = hash;
  await delSession();
}

/**
 *
 * @param data
 * @param any
 */
export function arrayToMapById(data: IArrayToMap[] = []) {
  let obj: any = {};

  data.forEach((d) => {
    if (!d.id) return;

    obj[d.id] = d;
  });

  return obj;
}

export function capitalizeFirstLetter(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function truncate(str: string, n: number) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export const currency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const nFormatter = (num: number, digits: number) => {
  var units = ["k", "M", "G", "T", "P", "E", "Z", "Y"],
    decimal;

  for (var i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      return `$${+(num / decimal).toFixed(digits) + units[i]}`;
    }
  }

  return `$${num}`;
};

/**
 * Dynamically get interface prop that doesn't have index while still being TS friendly
 * https://stackoverflow.com/a/60274490/14178931
 * @param key
 */
export const getKeyValue =
  <U extends keyof T, T extends object>(key: U) =>
  (obj: T) =>
    obj[key];

export const copyToClipboard = async (
  data: string,
  message: string = "Copied to Clipboard!"
) => {
  await navigator?.clipboard.writeText(data);
  FlowToast({ message: message });
};

/**
 *
 * @param lastEmailId
 * @param emails
 */
export function getActiveEmail(
  lastEmailId: string,
  emails: IEmail[] = []
): IEmail {
  let email: IEmail = emails[0] || { ...FlowFiData.email };

  if (lastEmailId) {
    email = emails.filter((e) => e.id === lastEmailId)[0] || email;
  }

  return email;
}

// Checks if a URL is valid.
export const validateUrl = (url: string | null) => {
  if (!url) return;

  try {
    new URL(url);
  } catch (e) {
    return false;
  }

  return true;
};
