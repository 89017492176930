import { v4 } from "uuid";
import { clone } from "ramda";
import { IFlowFiData } from "types/flowfi";
import { FlowFiData } from "data/flowfi";

export interface ICacheSubscription {
  [key: string]: Function;
}

export type TUserDataScopes =
  | "user"
  | "email"
  | "emails"
  | "companies"
  | "staff";

type TUserStore = Partial<Pick<IFlowFiData, TUserDataScopes>>;

const { user, email, emails, companies, staff } = FlowFiData;

export const initialUserStore: TUserStore = {
  user,
  email,
  emails,
  companies,
  staff,
};

const userStore = clone(initialUserStore);

/**
 *
 */
class UserCache {
  private subscriptions: ICacheSubscription = {};

  /**
   *
   * @param callback
   */
  subscribe(callback: Function): ICacheSubscription {
    let id = v4();

    this.subscriptions[id] = callback;

    return {
      unsubscribe: () => {
        delete this.subscriptions[id];
      },
    };
  }

  /**
   *
   * @param data
   */
  dispatch(data: Partial<IFlowFiData>) {
    Object.keys(this.subscriptions).forEach((sub) =>
      this.subscriptions[sub](data)
    );
  }

  /**
   *
   * @param data
   * @param config
   */
  async set(
    data: TUserStore,
    config: { clear: boolean } = { clear: false }
  ): Promise<TUserStore> {
    if (config.clear) {
      this.clear(Object.keys(data) as TUserDataScopes[]);
    }
    // deep copy each slice of state passed in and save it to the store
    Object.entries(data).forEach(([key, value]) => {
      Object.assign(userStore, { [key]: clone(value) });
    });

    this.dispatch(userStore);
    return userStore;
  }

  /**
   *
   * @param scopes
   * @param company_id
   * @param email_id
   */
  async get(
    scopes: TUserDataScopes[] = [],
    email_id: string = ""
  ): Promise<TUserStore> {
    const result: TUserStore = {};

    // get each scope from the store and return them in a new object
    scopes.forEach((scope) => {
      Object.assign(result, { [scope]: clone(userStore[scope]) });
    });
    return result;
  }

  /**
   *
   * @param scopes
   */
  async clear(
    scopes: TUserDataScopes[] = Object.keys(
      initialUserStore
    ) as TUserDataScopes[]
  ): Promise<TUserStore> {
    const defaultUserData = clone(initialUserStore);

    // set each scope in the store to its default value
    scopes.forEach((scope) => {
      Object.assign(userStore, { [scope]: defaultUserData[scope] });
    });

    this.dispatch(userStore);
    return userStore;
  }
}

export default new UserCache();
