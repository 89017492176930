export default `
id
company_id
name
type
data {
 accounts {
   list {
    id
    number
    name
    type
    parents
    children {
      id
      name
      type
      parents
      number
    }
   }
   tree {
    id
    number
    name
    type
    parents
    children {
      id
      number
      name
      type
      parents
      children {
        id
        number
        name
        type
        parents
        children {
          id
          number
          name
          type
          parents
          children {
            id
            number
            name
            type
          }
        }
      }
    }
   }
   graph {
    id
    number
    name
    type
   }
 }
 balances {
   account
   value
   type
   start
   end
 }
 bills {
  id
  description
  deep_link
  due_date
  original_balance
  remaining_balance
  transaction_date
  vendor_name
  vendor_email
  line_items {
    description
    original_amount
  }
  doc_number
 }
 invoices {
  id
  description
  deep_link
  due_date
  original_balance
  remaining_balance
  transaction_date
  customer_name
  customer_email
  line_items {
    description
    original_amount
  }
  doc_number
 }
 current {
   date
   subscriptions
 }
 previous {
    date
    subscriptions
 }
 vendorExpenses {
   id
   name
   amount
   date
 }
 balanceTotals {
   start
   end
   account
   value
   type
 }
}
`;
