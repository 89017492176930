import React from "react";
import { Route, Redirect } from "react-router-dom";

interface IConditionalRouteProps {
  path: string;
  checkCondition: () => boolean;
  fallbackPath: string;
  preserveParams?: boolean;
}

export const ConditionalRoute: React.FunctionComponent<IConditionalRouteProps> =
  ({ children, path, checkCondition, fallbackPath, preserveParams }) => (
    <Route
      path={path}
      render={({ location }) =>
        checkCondition() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: fallbackPath,
              search: preserveParams ? location.search : "",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
