import React from "react";
import styled from "styled-components";

interface IconProps {
  badgeNumber?: number;
  iconSrc: string;
  iconAlt: string;
}

const StyledImg = styled.img`
  height: auto;
  width: 20px;
`;

const Badge = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  padding: 0;
  top: 0%;
  left: 50%;
  font-size: 13px;
  color: white;
  background-color: #C53054;
}
`;

export class Icon extends React.Component<IconProps> {
  render() {
    const { iconSrc, iconAlt, badgeNumber } = this.props;
    return (
      <div className="parent-img">
        <StyledImg src={iconSrc} alt={iconAlt} />
        {badgeNumber ? <Badge>{badgeNumber}</Badge> : null}
      </div>
    );
  }
}
