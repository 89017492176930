import { IStaff } from "types/staff";
import { Response } from "system/resource";
import Staff from "resources/models/staff";
import axios from "axios";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

export interface IStaffStateResponse {
  staff: IStaff[];
}

/**
 * Merge Interfaces
 */
export interface StaffStateResponse extends IStaffStateResponse {}

/**
 * Merge Classes
 */
export class StaffStateResponse
  extends Response
  implements StaffStateResponse {}

/**
 *
 * @param user_id
 */
export default async function staff(user_id: string) {
  const body = JSON.stringify({
    query: `query getStateStaff($user_id: ID!) {
      getStateStaff(user_id: $user_id) {
        staff {
          ${Staff}
        }
      }
    }`,
    variables: {
      user_id,
    },
  });

  const request = (await api.post("", body)).data?.data?.getStateStaff?.staff;
  return new StaffStateResponse(request?.__typename, request);
}
