export default `
id
user_id
email_id
email_address
company_id
company_name
company_avatar
duration
state
created
first_name
last_name
role
`;
