export default `
id
provider
type
created
updated
company_id
access_token
realm_id
institution {
  id
  name
  logo
  accounts {
    id
    name
    type
    category
    last4
  }
}
`;
