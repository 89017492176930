import FlowFiError from "./error";
import { decrypt, encrypt } from "./security";
import { IUser } from "types/user";
import { mergeDeepRight } from "ramda";
import { FlowFiData } from "data/flowfi";
import { IEmail } from "types/email";
import { ICompany } from "types/company";
import { get as lsGet, set as lsSet, remove as lsDel } from "system/ls";

export interface ISession {
  token: string;
}

export interface ISessionData {
  user: IUser;
  emails: IEmail[];
  companies: ICompany[];
}

/**
 *
 * @param token
 */
export function create(token: string) {
  try {
    lsSet("session", token);
  } catch (e) {
    throw new FlowFiError({
      type: "CreateSession",
      message: (e as Error).message,
    });
  }
}

/**
 *
 */
export function get(): ISessionData {
  let session = lsGet("session");
  let decrypted = decrypt(session || "");
  return {
    user: { ...FlowFiData.user },
    emails: [],
    companies: [],
    ...decrypted,
  };
}

/**
 *
 * @param data
 */
export function update(data: Partial<ISessionData>) {
  let session = get() || {};

  let updated = mergeDeepRight(session, data);
  let token = encrypt(updated);

  lsSet("session", token);
}

export function remove() {
  lsDel("session");
}
