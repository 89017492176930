import FlowFiError from "./error";

/**
 * This is supposed to be super generic
 * as this class is meant to be extended and merged with a typesafe interface.
 */
interface IResponse {
  [key: string]: any;
}

/**
 * The response class is typically meant to be extended and merged with an interface
 * for stronger confidence in api response data however in an attempt to be DRY, we can reuse this class to parse
 * for GQL union responses/error by the existence of __typename.
 * This will prevent every api call from having to handle this directly.
 *
 * Additionally, Graphql Unions types make use ot the native __typename property which after the response is finished
 * has no purpose and we can delete for all responses at once here.
 */
class Response implements IResponse {
  constructor(typename: string, response: IResponse = {}) {
    // Not sure why this isnt being set by the default arg
    // but if 'null' is passed in it fails.
    response = response || {};

    // If type name is present it means the request had a GQL Union type response.
    // This way we get to globally catch errors and cast as a FlowFi error using the ddl-schema type.
    if (typename && typename === "FlowFiError") {
      throw new FlowFiError({
        id: response.id,
        message: response.message,
        type: response.type,
        created: response.created,
      });
    }

    // Uneeded after error handling.
    delete response.__typename;

    return response;
  }
}

export { Response };
