import React from "react";

import { Checklist, IChecklistCheck, CheckValues } from "components/checklist";
import { ConfirmButtons } from "components/confirm-buttons";
import { MonthPicker, IBalanceMonthRange } from "components/month-picker";
import { Select } from "components/select";
import { IFlowFiData } from "types/flowfi";
import { FlowToast } from "components/flow-toast";
import { clone, equals } from "ramda";
import exports from "resources/exports";
import { getActiveCompany } from "utilities/company";
import { flowFiDate } from "utilities/date-helpers";

interface IStatementReportsProps {
  data: IFlowFiData;
}

type MonthRange = "6" | "custom";

interface IStatementReportsState {
  statementCheckValues: CheckValues;
  monthRange: MonthRange;
  customMonthRange?: {
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
  };
  exporting: boolean;
}

export class StatementReports extends React.Component<IStatementReportsProps> {
  state: IStatementReportsState;
  defaultState: IStatementReportsState;
  balanceMonthRange: IBalanceMonthRange;

  constructor(props: IStatementReportsProps) {
    super(props);

    this.defaultState = {
      statementCheckValues: {
        pnl: 1,
        account_balance: 1,
        cash_flow: 1,
      },
      monthRange: "6",
      customMonthRange: undefined,
      exporting: false,
    };

    this.state = clone(this.defaultState);

    const lastMonth = flowFiDate({ detail: "month" }).subtract(1, "month");
    this.balanceMonthRange = {
      startYear: -Infinity,
      endYear: lastMonth.year(),
      startMonth: 0,
      endMonth: lastMonth.month(),
    };
  }

  handleStatementCheck = (statementCheckValues: CheckValues) => {
    this.setState({
      ...this.state,
      statementCheckValues,
    });
  };

  resetStatementSettings = () => {
    this.setState(clone(this.defaultState));
  };

  handleMonthRangeSelect(option: { label: string; value: string }) {
    this.setState({
      ...this.state,
      monthRange: option.value,
    });
  }

  showDateOrderError() {
    FlowToast({
      error: true,
      message: "Start date cannot be after end date.",
    });
  }

  setStartMonthAndYear(month: number, year: number) {
    this.setState(
      {
        ...this.state,
        customMonthRange: {
          ...this.state.customMonthRange,
          startMonth: month,
          startYear: year,
        },
      },
      () => {
        if (this.monthsOutOfOrder()) this.showDateOrderError();
      }
    );
  }

  setEndMonthAndYear(month: number, year: number) {
    this.setState(
      {
        ...this.state,
        customMonthRange: {
          ...this.state.customMonthRange,
          endMonth: month,
          endYear: year,
        },
      },
      () => {
        if (this.monthsOutOfOrder()) this.showDateOrderError();
      }
    );
  }

  monthsOutOfOrder() {
    if (this.state.customMonthRange) {
      const { startMonth, startYear, endMonth, endYear } =
        this.state.customMonthRange;
      if (
        [startMonth, startYear, endMonth, endYear].some(
          (val) => val === undefined
        )
      )
        return false;
      if (
        flowFiDate({
          date: { year: endYear, month: endMonth },
          detail: "month",
        }).isBefore(
          flowFiDate({
            date: { year: startYear, month: startMonth },
            detail: "month",
          })
        )
      ) {
        return true;
      }
    }
    return false;
  }

  formatRequestDate(
    startOrEnd: "start" | "end",
    monthRange: MonthRange,
    year: number | undefined,
    month: number | undefined
  ): string {
    if (monthRange !== "custom" || (!!year && !!month)) {
      const numMonths = startOrEnd === "end" ? 1 : 6;
      return flowFiDate({ detail: "month" })
        .subtract(numMonths, "months")
        .toISOString();
    }
    return flowFiDate({ date: { year, month }, detail: "month" }).toISOString();
  }

  handleExport = async () => {
    this.setState({ exporting: true });

    try {
      const activeCompany = getActiveCompany(this.props.data.companies);
      const zipUrl = await exports.reports(
        this.props.data.user.id,
        activeCompany.id,
        activeCompany.name,
        (
          this.props.data.connections.find(
            (c) => c.provider === "quickbooks"
          ) || {}
        ).id || "",
        this.formatRequestDate(
          "start",
          this.state.monthRange,
          this.state.customMonthRange?.startYear,
          this.state.customMonthRange?.startMonth
        ),
        this.formatRequestDate(
          "end",
          this.state.monthRange,
          this.state.customMonthRange?.endYear,
          this.state.customMonthRange?.endMonth
        ),
        Object.keys(this.state.statementCheckValues).filter(
          (key) => !!this.state.statementCheckValues[key]
        )
      );

      const a = document.createElement(`a`);
      a.href = zipUrl;
      a.click();
      a.remove();
    } catch (error) {
      const message =
        "Something went wrong with the export; please try again. If it fails again, please reach out to your bookkeeper for support.";
      FlowToast({
        error: true,
        message,
      });
    } finally {
      this.setState({ exporting: false });
    }
  };

  render() {
    const StatementCheckDisplayNames: { [key: string]: string } = {
      pnl: "Profit and Loss",
      account_balance: "Balance Sheet",
      cash_flow: "Statement of Cash Flow",
    };

    // Transforms this.state.statementCheckValues into IChecklistCheck[] type expected by CheckList component
    const StatementChecklist: IChecklistCheck[] = Object.keys(
      this.state.statementCheckValues
    ).map((key) => {
      return {
        name: key,
        label: StatementCheckDisplayNames[key],
        value: this.state.statementCheckValues[key],
      };
    });

    const resetButtonValidators = [
      !this.state.exporting,
      !equals(this.state, this.defaultState),
    ];

    const exportButtonValidators = [
      Object.values(this.state.statementCheckValues).some((value) => !!value), // some reports are checked
      !this.state.exporting, // reports aren't currently exporting
      this.state.monthRange === "6" || // monthRange is 6 OR // all customMonthRange(s) have been selected
        (this.state.customMonthRange?.startMonth !== undefined &&
          this.state.customMonthRange?.startYear !== undefined &&
          this.state.customMonthRange?.endMonth !== undefined &&
          this.state.customMonthRange?.endYear !== undefined &&
          !this.monthsOutOfOrder()), // AND months are not out of order
    ];

    return (
      <>
        <div className="flowfi-modal__body">
          <div className="flex pt-8">
            <div className="w-6/12">
              <label>
                Time Range
                <Select
                  value={this.state.monthRange}
                  transparent
                  options={[
                    {
                      value: "6",
                      label: "Prior 6 Months",
                    },
                    {
                      value: "custom",
                      label: "Custom",
                    },
                  ]}
                  onChange={(option) => {
                    this.handleMonthRangeSelect(option);
                  }}
                />
              </label>
            </div>
          </div>
          {this.state.monthRange === "custom" && (
            <MonthPicker
              balanceMonthRange={this.balanceMonthRange}
              startMonthValue={this.state.customMonthRange?.startMonth}
              endMonthValue={this.state.customMonthRange?.endMonth}
              startYearValue={this.state.customMonthRange?.startYear}
              endYearValue={this.state.customMonthRange?.endYear}
              onStartChange={(month: number, year: number) =>
                this.setStartMonthAndYear(month, year)
              }
              onEndChange={(month: number, year: number) =>
                this.setEndMonthAndYear(month, year)
              }
            />
          )}
          <div className="mt-8">
            <Checklist
              title="Bank Accounts"
              checks={StatementChecklist}
              handleCheck={this.handleStatementCheck}
            />
          </div>
        </div>
        <div className="flowfi-modal__footer">
          <ConfirmButtons
            leftButtonLabel="Reset"
            rightButtonLabel="Export"
            leftButtonValidators={resetButtonValidators}
            rightButtonValidators={exportButtonValidators}
            leftButtonOnClick={this.resetStatementSettings}
            rightButtonOnClick={this.handleExport}
            className=""
          />
        </div>
      </>
    );
  }
}
