import { IAsset } from "types/asset";
import { Response } from "system/resource";
import Asset from "resources/models/asset";
import axios from "axios";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

export interface IAssetsStateResponse {
  assets: IAsset[];
}

/**
 * Merge Interfaces
 */
export interface AssetsStateResponse extends IAssetsStateResponse {}

/**
 * Merge Classes
 */
export class AssetsStateResponse
  extends Response
  implements AssetsStateResponse {}

/**
 *
 * @param user_id
 * @param company_id
 */
export default async function assets(user_id: string, company_id: string) {
  const body = JSON.stringify({
    query: `query getStateAssets($user_id: ID!, $company_id: ID!) {
      getStateAssets(user_id: $user_id, company_id: $company_id) {
        assets {
          ${Asset}
        }
      }
    }`,
    variables: {
      user_id,
      company_id,
    },
  });

  const request = (await api.post("", body)).data?.data?.getStateAssets;
  return new AssetsStateResponse(request?.__typename, request);
}
