import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Badge from "components/badge";
import Classnames from "classnames";

interface INavBarLink {
  collapsed: boolean;
  label: string;
  icon: string;
  activeIcon: string;
  path: string;
  notificationCount?: number;
}

const NavBarLink = ({
  collapsed,
  label,
  icon,
  activeIcon,
  path,
  notificationCount,
}: INavBarLink): JSX.Element => {
  const linkClasses = Classnames("navigation-item", {
    "navigation-item--collapsed": collapsed,
  });
  const location = useLocation();
  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <li
      className={linkClasses}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      <NavLink
        to={{ pathname: path, search: location.search }}
        className="navigation-item__link"
        activeClassName="navigation-item__link--active"
      >
        <img src={hovering ? activeIcon : icon} alt={label} />
        {!collapsed && <span>{label}</span>}
        {notificationCount && <Badge count={notificationCount} />}
      </NavLink>
    </li>
  );
};

export default NavBarLink;
