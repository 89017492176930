export default `
id
state
name
domain
created
updated
stage
avatar
employees
location_city
location_state
location_zip
industry
`;
