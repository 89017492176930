import React from "react";
import ReactModal from "react-modal";
import { Close } from "assets/icons";
import styled from "styled-components";
import {
  ConfirmButtons,
  IConfirmButtonsProps,
} from "components/confirm-buttons";
import SimpleBar from "simplebar-react";
import Classnames from "classnames";
import "./modal.css";

interface IModalProps {
  isOpen: boolean;
  onClose: Function;
  children: React.ReactNode | React.ReactChild | React.ReactChildren;
  title?: string; // optional title
  subTitle?: string; // optional subtitle
  contentLabel?: string; // used for screen readers to be more descriptive.
  footerless?: boolean; // css hinting to add rounded corners to the wrapper
}

interface IConfirmModalProps extends IModalProps, IConfirmButtonsProps {}

// This is necessary for the modal library to initialize properly and not throw ARIA warnings
ReactModal.setAppElement("#root");

// Style config for the mechanical portions of the modal
export const ModalOverlayStyles = {
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const ModalContentStyles = {
  position: "static",
  top: "auto",
  left: "auto",
  right: "auto",
  bottom: "auto",
  border: "0",
  background: "transparent",
  minWidth: "250px",
  maxWidth: "50vw",
  maxHeight: "90vh",
  overflow: "visible",
  borderRadius: "2rem",
  outline: "none",
  padding: "0",
};

const ModalTitle = styled.h2`
  font-size: 30px;
`;

const ModalSubtitle = styled.h3`
  padding: 0 2rem;
  font-size: 20px;
`;

Object.assign(ReactModal.defaultStyles.overlay, ModalOverlayStyles);
Object.assign(ReactModal.defaultStyles.content, ModalContentStyles);

// Standard "build your own" modal.
export class FlowFiModal extends React.Component<IModalProps> {
  render() {
    const modalClasses = Classnames("flowfi-modal__wrapper", {
      "flowfi-modal__wrapper--footerless": this.props.footerless,
    });
    return (
      <ReactModal
        isOpen={this.props.isOpen || false}
        onRequestClose={() => this.props.onClose()}
        contentLabel={this.props.contentLabel || ""}
        closeTimeoutMS={200}
      >
        <div className={modalClasses}>
          <header className="flowfi-modal__header">
            {this.props.title && <ModalTitle>{this.props.title}</ModalTitle>}
            <button
              className="flowfi-modal__close"
              onClick={() => this.props.onClose()}
            >
              <img src={Close} alt={"close the modal" || "icon"} />
            </button>
          </header>
          {this.props.subTitle && (
            <ModalSubtitle>{this.props.subTitle}</ModalSubtitle>
          )}
          {this.props.children}
        </div>
      </ReactModal>
    );
  }
}

// This modal is used for confirmation-style popups
export class FlowFiConfirmModal extends React.Component<IConfirmModalProps> {
  handleLeftButtonOnClick() {
    this.props.leftButtonOnClick();
  }

  handleRightButtonOnClick() {
    this.props.rightButtonOnClick();
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen || false}
        onRequestClose={() => this.props.onClose()}
        contentLabel={this.props.contentLabel || ""}
        closeTimeoutMS={200}
      >
        <div className="flowfi-modal__wrapper">
          <header className="flowfi-modal__header">
            {this.props.title && <ModalTitle>{this.props.title}</ModalTitle>}
            <button
              className="flowfi-modal__close"
              onClick={() => this.props.onClose()}
            >
              <img src={Close} alt={"close this modal" || "icon"} />
            </button>
          </header>
          <div className="flowfi-modal__body">
            <SimpleBar style={{ height: "100%" }} autoHide>
              {this.props.subTitle && (
                <ModalSubtitle>{this.props.subTitle}</ModalSubtitle>
              )}
              {this.props.children}
            </SimpleBar>
          </div>
          <footer className="flowfi-modal__footer">
            <ConfirmButtons
              leftButtonLabel={this.props.leftButtonLabel}
              rightButtonLabel={this.props.rightButtonLabel}
              leftButtonValidators={this.props.leftButtonValidators}
              rightButtonValidators={this.props.rightButtonValidators}
              leftButtonOnClick={() => this.handleLeftButtonOnClick()}
              rightButtonOnClick={() => this.handleRightButtonOnClick()}
            />
          </footer>
        </div>
      </ReactModal>
    );
  }
}
