import React from "react";
import Classnames from "classnames";

import "./tab-bar.css";

interface ITabBarProps {
  tabs: {
    tabId: string;
    displayText: string;
    disabled?: boolean;
  }[];
  activeTabId: string;
  onClick: (tabId: string) => void;
  className?: string;
  tabClassName?: string;
}

export class TabBar extends React.Component<ITabBarProps> {
  tabClasses(tabId: string) {
    return Classnames(
      "tab-bar__tab",
      { "tab-bar__tab--active": tabId === this.props.activeTabId },
      this.props.tabClassName
    );
  }

  render() {
    return (
      <div className={Classnames("tab-bar", this.props.className)}>
        {this.props.tabs.map((tab) => (
          <button
            key={tab.tabId}
            className={this.tabClasses(tab.tabId)}
            onClick={() => this.props.onClick(tab.tabId)}
            disabled={tab.disabled}
          >
            {tab.displayText}
          </button>
        ))}
      </div>
    );
  }
}
