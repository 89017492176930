import jwt from "jsonwebtoken";
import { flowFiDate } from "utilities/date-helpers";
import { get as sGet, remove as sRemove } from "./session";

/**
 *
 * @param data
 */
export function encrypt(data: object, config = {}) {
  return jwt.sign(data, process.env.REACT_APP_SECRET || "", config);
}

/**
 *
 * @param data
 */
export function decrypt(token: string): Object {
  return jwt.verify(token, process.env.REACT_APP_SECRET || "");
}

/**
 *
 */
export function authenticated(): boolean {
  try {
    return !!sGet();
  } catch (e) {
    sRemove();
    return false;
  }
}

/**
 *
 * @param token
 */
export function isExpired(token: string): boolean {
  if (!token || !jwt.decode(token)) return true;
  const wrappedToken = jwt.decode(token);
  const iat = (wrappedToken as any).iat;
  const now = flowFiDate({ detail: "time" }).unix();
  const diffTime = now - iat > 3300;
  return diffTime;
}

/**
 *
 * @param token
 * @param uid
 */
export function wrap(token: string, uid: string): string {
  return jwt.sign({ token }, uid, { expiresIn: 55 * 60000 });
}

/**
 *
 * @param token
 * @param uid
 */
export function unwrap(token: string, uid: string): Object {
  return jwt.verify(token, uid);
}
