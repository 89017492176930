import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import LogoIcon from "assets/icons/logo.svg";
import LogoIconCollapsed from "assets/icons/logo-collapsed.svg";
import Classnames from "classnames";
import "./logo.css";

interface ILogo {
  collapsed: boolean;
}

const Logo = ({ collapsed }: ILogo): JSX.Element => {
  const logoClasses = Classnames("app-logo", {
    "app-logo--condensed": collapsed,
  });
  const location = useLocation();

  return (
    <h1 className={logoClasses}>
      <NavLink to={{ pathname: "/", search: location.search }}>
        <img src={collapsed ? LogoIconCollapsed : LogoIcon} alt="FlowFi" />
      </NavLink>
    </h1>
  );
};

export default Logo;
