import React from "react";
import * as htmlToImage from "html-to-image";

import { Checklist, IChecklistCheck, CheckValues } from "components/checklist";
import { ConfirmButtons } from "components/confirm-buttons";
import { ICardRefs, ICardImage } from "types/card";
import { IFlowFiData } from "types/flowfi";
import { FlowToast } from "components/flow-toast";
import exports from "resources/exports";
import { getActiveCompany } from "utilities/company";
import { flowFiDate } from "utilities/date-helpers";

interface IDashboardReportsProps {
  data: IFlowFiData;
  cardRefs: ICardRefs;
}

interface IDashboardReportsState {
  dashboardCheckValues: CheckValues;
  capturingScreenshots: boolean;
}

export class DashboardReports extends React.Component<IDashboardReportsProps> {
  state: IDashboardReportsState;
  defaultDashboardCheckValues: CheckValues;

  constructor(props: IDashboardReportsProps) {
    super(props);

    /**
     * Creates a CheckValues object with keys based on CardRefs
     * from `app-container` to track the state of checkbox values.
     */
    const cardsWithDefaultTrue = ["balance", "burn", "revenue", "spend"];
    this.defaultDashboardCheckValues = {};
    for (const key of Object.keys(this.props.cardRefs)) {
      Object.assign(this.defaultDashboardCheckValues, {
        [key]: cardsWithDefaultTrue.includes(key) ? 1 : 0,
      });
    }

    this.state = {
      dashboardCheckValues: this.defaultDashboardCheckValues,
      capturingScreenshots: false,
    };
  }

  handleDashboardCheck = (dashboardCheckValues: CheckValues) => {
    this.setState({
      ...this.state,
      dashboardCheckValues,
    });
  };

  determineDashboardChecksHaveChanged() {
    const keys = Object.keys(this.defaultDashboardCheckValues);
    for (let key of keys) {
      if (
        this.defaultDashboardCheckValues[key] !==
        this.state.dashboardCheckValues[key]
      ) {
        return true;
      }
    }
    return false;
  }

  resetDashboardChecks = () => {
    this.setState({ dashboardCheckValues: this.defaultDashboardCheckValues });
  };

  // Loops through CardRefs[] and takes a screenshot for each ref
  captureScreenshots = async () => {
    const dateStamp = flowFiDate().format("YYYY-MM-DD");
    const checkedCards = Object.entries(this.props?.cardRefs).filter(
      (card) => this.state.dashboardCheckValues[card[0]]
    );

    const images = [];
    for (const card of checkedCards) {
      const png = await htmlToImage.toPng(card[1].current as HTMLElement);
      images.push({
        fileName: `${card[0]}-export-${dateStamp}.png`,
        image: png,
      });
    }
    return images;
  };

  /**
   * handleCapture is necessary because captureScreenshots must be wrapped in a...
   * - ...Promise to ensure that the finally in handleExport is executed
   *   async after handleExport has either succeeded or failed
   * - ...setTimeout to ensure the first setState in handleExport causes an
   *   immediate render. Without it, the disabled state of the export button
   *   is deferred to a later render.
   */
  handleCapture(): Promise<ICardImage[]> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          resolve(await this.captureScreenshots());
        } catch (e) {
          reject(e);
        }
      }, 0);
    });
  }

  handleExport = async () => {
    this.setState({ capturingScreenshots: true });

    try {
      const cardImages: ICardImage[] = await this.handleCapture();
      const activeCompany = getActiveCompany(this.props.data.companies);

      if (!!cardImages) {
        const zipUrl = await exports.images(
          this.props.data.user.id,
          activeCompany.id,
          activeCompany.name,
          flowFiDate().format("YYYY-MM-DD"),
          cardImages
        );

        const a = document.createElement(`a`);
        a.href = zipUrl;
        a.click();
        a.remove();
      }
    } catch (error) {
      const message = "Something went wrong with the export.";
      FlowToast({
        error: true,
        message,
      });
    } finally {
      this.setState({ capturingScreenshots: false });
    }
  };

  render() {
    const DashboardCheckDisplayNames: { [key: string]: string } = {
      balance: "Cash Balances",
      burn: "Burn Rate",
      revenue: "Revenue",
      spend: "Spending",
      bills: "Bills",
      invoices: "Invoices",
      topExpenses: "Top Expenses",
      expenseDistribution: "Expense Distribution",
      topPaidVendors: "Top Paid Vendors",
    };

    // Transforms this.state.dashboardCheckValues into IChecklistCheck[] format needed by CheckList component
    const dashboardChecklist: IChecklistCheck[] = Object.keys(
      this.state.dashboardCheckValues
    ).map((key) => {
      return {
        name: key,
        label: DashboardCheckDisplayNames[key],
        value: this.state.dashboardCheckValues[key],
      };
    });

    const resetButtonValidators = [
      !this.state.capturingScreenshots,
      this.determineDashboardChecksHaveChanged(),
    ];

    /**
     * exportButtonValidators[]:
     * [0]: some values must be checked
     * [1]: captureScreenshots function cannot be in progress
     * [2]: every checked card must have an existing (rendered) card ref
     */
    const exportButtonValidators = [
      Object.values(this.state.dashboardCheckValues).some((value) => !!value),
      !this.state.capturingScreenshots,
      Object.keys(this.state.dashboardCheckValues).every((key) =>
        this.state.dashboardCheckValues[key]
          ? !!this.props.cardRefs[key]?.current
          : true
      ),
    ];

    return (
      <>
        <div className="flowfi-modal__body pt-8">
          <Checklist
            title="Reports"
            checks={dashboardChecklist}
            handleCheck={this.handleDashboardCheck}
          />
        </div>
        <div className="flowfi-modal__footer">
          <ConfirmButtons
            leftButtonLabel="Reset"
            rightButtonLabel="Export"
            leftButtonValidators={resetButtonValidators}
            rightButtonValidators={exportButtonValidators}
            leftButtonOnClick={this.resetDashboardChecks}
            rightButtonOnClick={this.handleExport}
          />
        </div>
      </>
    );
  }
}
