import React from "react";

import { FlowFiModal } from "components/modal";
import { Reports } from "pages/Dashboard/cards-v2/reports";
import { ICardRefs } from "types/card";
import { IFlowFiData } from "types/flowfi";
import { Export } from "assets/icons";
import "./export-button.css";

interface IExportButtonProps {
  data: IFlowFiData;
  cardRefs: ICardRefs;
}

interface IExportButtonState {
  modalOpen: boolean;
}

export class ExportButton extends React.Component<IExportButtonProps> {
  state: IExportButtonState;

  constructor(props: IExportButtonProps) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  render() {
    return (
      <>
        <button
          className="appearance-none hidden ml-auto lg:block"
          onClick={() => this.setState({ modalOpen: true })}
        >
          <img
            className="export-button__icon"
            src={Export}
            alt={"Export reports"}
          />
        </button>
        <FlowFiModal
          isOpen={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          title="Reports"
        >
          <Reports data={this.props.data} cardRefs={this.props.cardRefs} />
        </FlowFiModal>
      </>
    );
  }
}
