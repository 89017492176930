import { LinkProps } from "react-router-dom";
import { TConnectionProvider } from "types/connection";

export interface IUrl {
  search: string | null;
  query: {
    code?: string;
    realmId?: string;
    state?: string;
  };
}

export interface IUrlQueryState {
  path?: string;
  query?: string;
  provider?: TConnectionProvider;
  id?: string;
}

export function getParams() {
  const paramsObject: { [key: string]: string } = {};
  const url = window.location.href;
  const splitUrl = url.split("?");
  if (splitUrl.length < 2) return paramsObject;

  splitUrl
    ?.pop()
    ?.split("&")
    ?.forEach((pair) => {
      const [key, val] = pair.split("=");
      Object.assign(paramsObject, { [key]: val });
    });

  return paramsObject;
}

export function getActiveCompanyId(): string {
  return getParams().company_id ?? "";
}

export function getPathWithCompanyId(
  pathname: string,
  companyId: string
): string {
  const companyIdKey = "company_id";
  const paramPairs = getParams();
  const paramEntries = Object.entries(paramPairs);
  const paramsHaveCompanyId = !!paramPairs[companyIdKey];
  if (!paramsHaveCompanyId && !!companyId)
    paramEntries.push([companyIdKey, companyId]);

  const newParams =
    (paramEntries.length ? "?" : "") +
      paramEntries
        .map((paramPair) => {
          const [key, value] = paramPair;
          if (key === companyIdKey) {
            return `${key}=${companyId}`;
          }
          return `${key}=${value}`;
        })
        .join("&") || "";

  const newPathName =
    (pathname.endsWith("/")
      ? pathname.slice(0, pathname.length - 1)
      : pathname) || "/";
  return newPathName + newParams;
}

export function getLocationDescriptor(
  pathname: string,
  subpath: string,
  searchParams: string
): LinkProps["to"] {
  return {
    pathname: `${pathname}${subpath ? `/${subpath}` : subpath}`,
    search: searchParams,
  };
}
