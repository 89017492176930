import React from "react";
import Classnames from "classnames";
import ChevronLeft from "assets/icons/chevron-left.svg";
import ChevronRight from "assets/icons/chevron-right.svg";

interface INavBarToggle {
  collapsed: boolean;
  onClick: () => void;
}

const NavBarToggle = ({ onClick, collapsed }: INavBarToggle): JSX.Element => {
  const toggleClasses = Classnames("navigation-toggle", {
    "navigation-toggle--collapsed": collapsed,
  });

  return (
    <button
      className={toggleClasses}
      onClick={onClick}
      title="Toggle Navigation"
    >
      <img
        className="navigation-toggle__icon"
        src={collapsed ? ChevronRight : ChevronLeft}
        alt={collapsed ? "Expand Navigation" : "Collapse Navigation"}
      />
    </button>
  );
};

export default NavBarToggle;
