import { IError } from "types/error";

/**
 *
 */
export default class FlowFiError extends Error implements IError {
  public type: string;
  public id: string;
  public created: string;

  /**
   *
   * @param error
   */
  constructor(error: IError) {
    super();

    this.message = error.message || "";
    this.type = error.type || "";
    this.id = error.id || "";
    this.created = error.created || "";
  }
}
