import { IEmail } from "types/email";
import { Response } from "system/resource";
import Email from "resources/models/email";
import axios from "axios";

/**
 *
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_STATE_API}/state`,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 *
 */
export interface IEmailStateResponse {
  emails: IEmail[];
}

/**
 * Merge Interfaces
 */
export interface EmailStateResponse extends IEmailStateResponse {}

/**
 * Merge Classes
 */
export class EmailStateResponse
  extends Response
  implements EmailStateResponse {}

/**
 *
 * @param user_id
 */
export default async function emails(user_id: string) {
  const body = JSON.stringify({
    query: `query getStateEmails($user_id: ID!) {
      getStateEmails(user_id: $user_id) {
        emails {
          ${Email}
        }
      }
    }`,
    variables: {
      user_id,
    },
  });

  const request = (await api.post("", body)).data?.data?.getStateEmails;
  return new EmailStateResponse(request?.__typename, request);
}
