import CompanyCache from "caches/company";
import UserCache, { ICacheSubscription } from "caches/user";
import TopNav from "components/top-nav";
import { FlowFiData } from "data/flowfi";
// Pages
import {
  Dashboard,
  Invite as InvitePage,
  Login,
  Registration,
  Settings,
  Document,
  Documents,
  Team,
  Companies,
  ActionItems,
} from "pages";
import React, { Suspense } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import TopBarProgress from "react-topbar-progress-indicator";
import Url from "url";
import NavBar from "components/navbar";
// Routes
import ROUTES from "routes";
import { IFlowFiData, IFlowFiState } from "types/flowfi";
import ErrorBoundary from "components/error-boundary";
import { ConditionalRoute } from "components/conditional-route";
// System
import { get as getSession } from "system/session";
import { authenticated } from "system/security";
import { buildCompanyData, companyIdIsValid } from "utilities/company";
import {
  getActiveCompanyId,
  getPathWithCompanyId,
  IUrl,
  IUrlQueryState,
} from "utilities/routing";
import "./styles/index.css";

TopBarProgress.config({
  barColors: {
    "0": "#00E4D1",
    "0.5": "#02A5A0",
    "1.0": "#070044",
  },
  barThickness: 3,
  shadowBlur: 5,
});

export class AppContainer extends React.Component {
  state: IFlowFiState;

  constructor(props = {}) {
    super(props);

    let session;

    try {
      session = getSession();
    } catch (e) {
      //
    }

    let data: IFlowFiData = { ...FlowFiData, ...session };
    this.state = { data: data, loading: true };
  }

  userCacheHandler: ICacheSubscription = UserCache.subscribe(
    this.cacheHandler.bind(this)
  );

  companyCacheHandler: ICacheSubscription = CompanyCache.subscribe(
    this.cacheHandler.bind(this)
  );

  cacheHandler(data: Partial<IFlowFiData>) {
    this.setState({ ...this.state, data: { ...this.state.data, ...data } });
  }

  async componentDidMount() {
    const url: IUrl = Url.parse(window.location.href, true);

    if (url?.query?.code && url?.query?.state) {
      const urlState: IUrlQueryState = JSON.parse(url.query.state);
      window.location.hash = `#${urlState.path}${
        !!urlState.query ? `?${urlState.query}` : ""
      }`;
    }

    await this.selectCompany(getActiveCompanyId());
  }

  async selectCompany(companyId: string, path: string = "") {
    const currentPath =
      window.location.hash.split("#/").pop()?.split("?")?.shift() || "";
    const selectedCompanyPath = getPathWithCompanyId(
      path ? "#" + path : "#/" + currentPath,
      companyId
    );
    window.location.hash = selectedCompanyPath;
    this.setState({ loading: true });
    await buildCompanyData();
    this.setState({ loading: false });
  }

  cardRefs = {
    balance: React.createRef<HTMLDivElement>(),
    bills: React.createRef<HTMLDivElement>(),
    revenue: React.createRef<HTMLDivElement>(),
    spend: React.createRef<HTMLDivElement>(),
    invoices: React.createRef<HTMLDivElement>(),
    burn: React.createRef<HTMLDivElement>(),
    topExpenses: React.createRef<HTMLDivElement>(),
    // expenseDistribution: React.createRef<HTMLDivElement>(),
    topPaidVendors: React.createRef<HTMLDivElement>(),
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <div></div>
        ) : (
          <Router>
            <Suspense fallback={<TopBarProgress />}>
              <Switch>
                <Route path={ROUTES.registration.path}>
                  <Registration data={this.state.data} />
                </Route>
                <Route path={ROUTES.error.path}>
                  <ErrorBoundary errorPage={true} />
                </Route>
                <Route path={ROUTES.login.path}>
                  <Login data={this.state.data} />
                </Route>
                <Route path={ROUTES.invitation.path}>
                  <InvitePage
                    user={this.state.data.user}
                    email={this.state.data.email}
                  />
                </Route>
                <ConditionalRoute
                  path={ROUTES.root.path}
                  checkCondition={authenticated}
                  fallbackPath={ROUTES.login.path}
                  preserveParams={true}
                >
                  <Switch>
                    <Route path={ROUTES.companies.path}>
                      <Companies
                        data={this.state.data}
                        selectCompany={this.selectCompany.bind(this)}
                      />
                    </Route>
                    <ConditionalRoute
                      path={ROUTES.root.path}
                      checkCondition={() =>
                        companyIdIsValid(this.state.data.companies)
                      }
                      fallbackPath={ROUTES.companies.path}
                    >
                      <div className="app-container">
                        <NavBar data={this.state.data} collapsed={false} />
                        <div className="app-view-container">
                          <TopNav
                            data={this.state.data}
                            cardRefs={this.cardRefs}
                            selectCompany={this.selectCompany.bind(this)}
                          />
                          <Switch>
                            <Route
                              exact
                              path={ROUTES.root.path}
                              render={(props) => (
                                <Redirect
                                  to={{
                                    pathname: ROUTES.dashboard.path,
                                    search: props.location.search,
                                  }}
                                />
                              )}
                            />
                            <Route path={ROUTES.dashboard.path}>
                              <Dashboard
                                data={this.state.data}
                                cardRefs={this.cardRefs}
                              />
                            </Route>
                            <Route
                              exact
                              path={ROUTES.assets.subroutes.document.path}
                            >
                              <Document data={this.state.data} />
                            </Route>
                            <Route path={ROUTES.assets.path}>
                              <Documents data={this.state.data} />
                            </Route>
                            <Route path={ROUTES.actionItems.path}>
                              <ActionItems data={this.state.data} />
                            </Route>
                            <Route path={ROUTES.settings.path}>
                              <Settings data={this.state.data} />
                            </Route>
                            <Route path={ROUTES.team.path}>
                              <Team data={this.state.data} />
                            </Route>
                          </Switch>
                        </div>
                      </div>
                    </ConditionalRoute>
                  </Switch>
                </ConditionalRoute>
              </Switch>
            </Suspense>
          </Router>
        )}
      </>
    );
  }
}
