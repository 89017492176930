import React from "react";

import styled from "styled-components";
import { TabBar } from "components/tab-bar";
import { DashboardReports } from "./dashboard-reports";
import { StatementReports } from "./statement-reports";
import { ICardRefs } from "types/card";
import { IFlowFiData } from "types/flowfi";
import "./reports.css";

// added to keep style same as FlowFiCard subtitle
const StyledSubtitle = styled.h6`
  padding: 0 2rem;
  margin: 0;
  font-size: 20px;
`;

interface IReportsProps {
  data: IFlowFiData;
  cardRefs: ICardRefs;
}

interface IReportsState {
  activeTab: "statements" | "dashboard";
}

export class Reports extends React.Component<IReportsProps> {
  state: IReportsState;

  constructor(props: IReportsProps) {
    super(props);

    this.state = {
      activeTab: "dashboard",
    };
  }

  tabBarTabs = [
    {
      tabId: "statements",
      displayText: "Financial Statements",
    },
    {
      tabId: "dashboard",
      displayText: "Flow Dashboard Reports",
    },
  ];

  tabBarOnClick = (tabId: string) => {
    this.setState({ ...this.state, activeTab: tabId });
  };

  render() {
    return (
      <>
        <div className="reports-modal__wrap">
          <StyledSubtitle>
            {this.state.activeTab === "statements"
              ? "CSV exports for financial analysis"
              : "PNG exports for presentations, emails, etc"}
          </StyledSubtitle>
          <div className="short-horizontal-separator" />
          <TabBar
            className="reports__tab-bar"
            tabs={this.tabBarTabs}
            activeTabId={this.state.activeTab}
            onClick={this.tabBarOnClick}
          />
          {this.state.activeTab === "statements" ? (
            <StatementReports data={this.props.data} />
          ) : (
            <DashboardReports
              data={this.props.data}
              cardRefs={this.props.cardRefs}
            />
          )}
        </div>
      </>
    );
  }
}
